import React, { MutableRefObject, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { Room, LocalDataTrack } from 'twilio-video';
import { useRouteMatch, useHistory } from 'react-router-dom';
import useRoom from '../hooks/useRoom';
import useApi, { Meeting } from '../hooks/useApi';
import useMeetingsContext from '../hooks/useMeetingsContext';
import useAuthenticationContext from '../hooks/useAuthenticationContext';
import useVideoContext from '../hooks/useVideoContext';
import { ensureMediaPermissions } from '../utils';
import { EventEmitter } from 'events';
import {
  changeMainParticipantEventCreator,
  DataChannelEvent,
  endMeetingEventCreator,
  BinaryMessage,
} from '../utils/dataChannelEvents';
import { confirmAlert } from 'react-confirm-alert';

type ItemId = number;
type ShowroomViewState = 'closed' | 'list' | 'cart' | ItemId;

interface MeetingContext {
  id: string;
  meeting: Meeting | null;
  room: Room;
  isOwner: boolean;
  ownerId: string;
  videoRef: MutableRefObject<HTMLVideoElement | null>;

  mainScreenParticipantId: number | null;
  selectMainScreenParticipant: (participantId: number) => void;

  showroomViewState: ShowroomViewState;
  setShowroomViewState: (state: ShowroomViewState) => void;

  dataChannelEventBus: EventEmitter;
  emmitDataChannelEvent: (Event: DataChannelEvent<any>) => void;

  isMediaConfigured: boolean;
  setIsMediaConfigured: (state: boolean) => void;

  onLeaveMeeting: () => void;
}

export const MeetingContext = React.createContext<MeetingContext>(null!);

const MeetingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { localTracks } = useVideoContext();
  const match = useRouteMatch<{ id: string }>('/meeting/:id');
  const [meeting, setMeeting] = useState<Meeting | null>(null);

  const dataChannelEventBus = useRef(new EventEmitter());

  const [mainScreenParticipantId, setMainScreenParticipantId] = useState<number | null>(null);

  const [isMediaConfigured, setIsMediaConfigured] = useState<boolean>(false);

  const { user } = useAuthenticationContext();

  const { room, connect } = useRoom(localTracks, () => {});

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { getMeeting } = useMeetingsContext();

  useEffect(() => {
    getMeeting(match?.params.id!).then(setMeeting);
  }, [getMeeting, match]);

  const [connected, setConnected] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [ownerId, setOwnerId] = useState('');
  const { get: getShowroom } = useApi().showrooms;

  const history = useHistory();

  const [showroomViewState, setShowroomViewState] = useState<ShowroomViewState>('closed');

  useEffect(() => {
    if (!meeting || connected || !isMediaConfigured) {
      return;
    }
    setConnected(true);

    ensureMediaPermissions().then(() =>
      getShowroom(meeting.room_id)
        .then(resp => resp.json())
        .then(showroom => {
          const isOwner = showroom.owner.id === user?.id;
          setIsOwner(isOwner);
          setOwnerId(showroom.owner.id);
          setMainScreenParticipantId(Number(showroom.owner.id));
          return connect(showroom.twilio.token, isOwner);
        })
    );
  }, [meeting, connect, connected, localTracks, match, getShowroom, isOwner, setIsOwner, ownerId, setOwnerId, user]);

  useEffect(
    () => () => {
      if (room.disconnect) {
        room.disconnect();
      }
    },
    [room]
  );

  const dataTrack = useMemo(() => localTracks.find(({ kind }) => kind === 'data') as LocalDataTrack, [localTracks]);

  const emmitDataChannelEvent = useCallback(
    (event: DataChannelEvent<any>) => {
      event.sourceUserId = user!!.id;

      let binary = new BinaryMessage();
      let data = binary.convertTo(event);
      if (data) {
        console.log('emiting event', event);
        dataTrack.send(data);
      }
    },
    [dataTrack]
  );

  const selectMainScreenParticipant = (participantId: number) => {
    if (isOwner) {
      emmitDataChannelEvent(changeMainParticipantEventCreator(participantId));
    }

    setMainScreenParticipantId(participantId);
  };

  const onLeaveMeeting = useCallback(() => {
    if (isOwner) {
      confirmAlert({
        message: 'Would you like to end the meeting for everybody?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              emmitDataChannelEvent(endMeetingEventCreator());
              history.goBack();
            },
          },
          { label: 'No, just leave', onClick: () => history.push('/meetings/current') },
          { label: 'Cancel', onClick: () => {} },
        ],
      });
      return;
    }

    confirmAlert({
      message: 'Are you sure, you want to leave the meeting?',
      buttons: [
        { label: 'Yes', onClick: () => history.push('/meetings/current') },
        { label: 'Cancel', onClick: () => {} },
      ],
    });
  }, [isOwner]);

  return (
    <MeetingContext.Provider
      value={{
        id: match?.params.id!,
        meeting,
        videoRef,
        room,
        isOwner,
        ownerId,
        selectMainScreenParticipant,
        mainScreenParticipantId,
        showroomViewState,
        setShowroomViewState,
        dataChannelEventBus: dataChannelEventBus.current,
        emmitDataChannelEvent,
        onLeaveMeeting,
        isMediaConfigured,
        setIsMediaConfigured,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

export default MeetingContextProvider;
