import { useContext } from 'react';
import { MeetingsContext } from '../state/MeetingsContext';

export default function useMeetingsContext() {
  const context = useContext(MeetingsContext);
  if (!context) {
    throw new Error('useMeetingsContext must be used within a MeetingsContextProvider');
  }
  return context;
}
