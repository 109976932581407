import React, { useRef, useEffect, Ref, useImperativeHandle } from 'react';
import { IVideoTrack } from '../types';
import { Track } from 'twilio-video';
import styled from 'styled-components';

const Video = styled('video')`
  width: 100%;
  height: 100%;
`;

interface VideoTrackProps {
  className?: string;
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const VideoTrack = React.forwardRef(
  ({ className, track, isLocal, priority }: VideoTrackProps, ref: Ref<HTMLVideoElement>) => {
    const innerRef = useRef<HTMLVideoElement>(null!);

    useImperativeHandle(ref, () => innerRef.current);

    useEffect(() => {
      const el = innerRef.current;

      el.muted = true;
      if (track.setPriority && priority) {
        track.setPriority(priority);
      }
      track.attach(el);
      return () => {
        track.detach(el);
        if (track.setPriority && priority) {
          // Passing `null` to setPriority will set the track's priority to that which it was published with.
          track.setPriority(null);
        }
      };
    }, [track, priority]);

    // The local video track is mirrored.
    const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
    const style = isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

    return <Video className={className} ref={innerRef} style={style} />;
  }
);

export default VideoTrack;
