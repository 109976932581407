import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Text from './Text';
import UserAvatar from './UserAvatar';
import Color from 'color';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const HeaderBarContainer = styled.div<{ floating: boolean }>`
  ${({ floating }) => floating && 'position: absolute'};
  background: ${({ theme }) =>
    Color(theme.colors.white)
      .fade(0.1)
      .string()};
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`;

export const HeaderText = styled(Text)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* new css */
  max-width: 100%;
`;

const HeaderBarChildContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderControls = styled.div`
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 1em 1em 1em;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderBarUserAvatar = styled(UserAvatar)`
  font-size: 2rem;
`;

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

interface HeaderBarProps {
  title?: string;
  floating?: boolean;
  children?: ReactNode;
  backUrl?: string;
  controls?: ReactNode;
}

const HeaderBar = ({ title, floating, children, backUrl, controls }: HeaderBarProps) => {
  return (
    <HeaderBarContainer floating={floating || false}>
      {controls && <HeaderControls> {controls} </HeaderControls>}
      <HeaderContent>
        <HeaderText>
          {' '}
          {backUrl && <BackLink to={backUrl}>&lt;</BackLink>} {title}
        </HeaderText>

        <HeaderBarChildContainer>{children}</HeaderBarChildContainer>
      </HeaderContent>
    </HeaderBarContainer>
  );
};

export const HeaderBarWithAvatar = ({ title, floating }: HeaderBarProps) => {
  const history = useHistory();
  return (
    <HeaderBar title={title} floating={floating}>
      <HeaderBarUserAvatar onClick={() => history.push('/profile')} />
    </HeaderBar>
  );
};

export default HeaderBar;
