import React, { useEffect } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { LocalVideoTrack } from 'twilio-video';
import { useHistory } from 'react-router-dom';

import { IVideoTrack } from '../../../types';
import { Close, CamOff, CamOn, MicOff, MicOn } from '../../../components/icons';
import VideoTrack from '../../../components/VideoTrack';

import useMeetingContext from '../../../hooks/useMeetingContext';
import useVideoContext from '../../../hooks/useVideoContext';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle';

const OverlayContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
`;

const DialogHeader = styled.div`
  width: 100%;
  height: 4rem;
  background: white;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family};
`;

const DialogBody = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoTrackContainer = styled.div`
  max-width: 36rem;
  min-width: 28rem;
  max-height: 26rem;
  min-height: 20rem;
  border-radius: 0.7rem;
  position: relative;

  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
`;

const VideoTrackPreview = styled(VideoTrack)`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: black;
`;

const Controls = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: center;
  bottom: 1rem;
`;

const NoVideo = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: black;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const JoinMeetingButton = styled.button`
  margin-top: 3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-family: sans-serif;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 2.5rem;
  border: none;
  width: 9rem;
  height: 3.4rem;
`;

const MeetingPreview = ({ onClose }: { onClose: () => void }) => {
  const { id, meeting, setIsMediaConfigured, isMediaConfigured } = useMeetingContext();
  const { localTracks, cameraDisabled, microphoneDisabled } = useVideoContext();
  const [video, toggleVideoEnabled] = useLocalVideoToggle();
  const [audio, toggleAudioEnabled] = useLocalAudioToggle();
  const history = useHistory();

  const track = localTracks.find(track => {
    return track.kind === 'video';
  }) as LocalVideoTrack;

  const startMeeting = () => {
    setIsMediaConfigured(true);
  };

  const onPreviewClose = () => {
    localTracks.find(track => {
      if (track.kind === 'video' || track.kind === 'audio') track.stop();
    });

    history.push('/meetings/current');
  };

  const commonProps = {
    size: 3,
    circle: true,
    borderSize: '0',
    circleSize: 0.55,
    color: 'white',
    background: Color('black')
      .fade(0.6)
      .string(),
    hoverBackground: Color('grey')
      .fade(0.6)
      .string(),
  };

  const disabledControlColor = Color('red')
    .fade(0.4)
    .string();

  const hoverDisabledBackground = Color('red')
    .fade(0.7)
    .string();

  return (
    <>
      <OverlayContainer>
        <div style={{}}>
          <DialogHeader>
            {meeting?.name}
            <Close
              {...commonProps}
              style={{ display: 'inline-block', padding: 0, position: 'absolute', right: '2rem' }}
              onClick={onPreviewClose}
              background={Color('white')
                .fade(0.1)
                .string()}
              hoverBackground="none"
              color="gray"
            />
          </DialogHeader>
          <DialogBody>
            <VideoTrackContainer>
              {track ? <VideoTrackPreview track={track} /> : <NoVideo>Camera Off</NoVideo>}
              <Controls>
                <div style={{ marginRight: '1rem' }}>
                  {audio ? (
                    <MicOn {...commonProps} onClick={toggleAudioEnabled} />
                  ) : (
                    <MicOff
                      {...commonProps}
                      hoverBackground={hoverDisabledBackground}
                      background={disabledControlColor}
                      onClick={toggleAudioEnabled}
                    />
                  )}
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  {video ? (
                    <CamOn {...commonProps} onClick={toggleVideoEnabled} />
                  ) : (
                    <CamOff
                      {...commonProps}
                      hoverBackground={hoverDisabledBackground}
                      background={disabledControlColor}
                      onClick={toggleVideoEnabled}
                    />
                  )}
                </div>
              </Controls>
            </VideoTrackContainer>
            <JoinMeetingButton onClick={startMeeting}>Join Now</JoinMeetingButton>
          </DialogBody>
        </div>
      </OverlayContainer>
    </>
  );
};

export default MeetingPreview;
