import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as heart } from './shoptime_icon-heart.svg';
import { ReactComponent as camera } from './shoptime_icon-camera.svg';
import { ReactComponent as bag } from './shoptime_icon-handbag.svg';
import { ReactComponent as chat } from './shoptime_icon-chat.svg';
import { ReactComponent as share } from './shoptime_icon-share.svg';
import { ReactComponent as showrooms } from './shoptime_icon-showroom.svg';
import { ReactComponent as link } from './shoptime_icon-link.svg';
import { ReactComponent as eye } from './shoptime_icon-eye.svg';
import { ReactComponent as calendar } from './shoptime_icon-cal-2.svg';
import { ReactComponent as meetings } from './shoptime_icon-cal-1.svg';
import { ReactComponent as cart } from './shoptime_icon-cart.svg';
import { ReactComponent as document } from './shoptime_icon-document.svg';
import { ReactComponent as swapCamera } from './shoptime_icon-swapcam.svg';
import { ReactComponent as close } from './shoptime_icon-close.svg';
import { ReactComponent as micOn } from './shoptime_icon-mic-on.svg';
import { ReactComponent as micOff } from './shoptime_icon-mic-off.svg';
import { ReactComponent as camOn } from './shoptime_icon-cam-on.svg';
import { ReactComponent as camOff } from './shoptime_icon-cam-off.svg';
import { ReactComponent as cameraWithHeart } from './shoptime_icon-camera-heart.svg';
import { ReactComponent as avatar } from './shoptime_icon-avatar.svg';
import { ReactComponent as email } from './shoptime_icon-envelope.svg';
import { ReactComponent as thumbsUp } from './shoptime_icon-thumbsup.svg';
import { ReactComponent as chargeCard } from './shoptime_icon-chargecard.svg';
import { ReactComponent as gear } from './shoptime_icon-gear.svg';
import { ReactComponent as plus } from './shoptime_icon-plus.svg';
import { ReactComponent as time } from './shoptime_icon-time.svg';
import { ReactComponent as trash } from './shoptime_icon-trash.svg';

import { ReactComponent as checkmark } from './check-tick-svgrepo-com.svg';

export interface BaseIconProps {
  onClick?: () => void;
  style?: any;
  size?: number;
  circle?: undefined | false | true | 'background';
  background?: string;
  active?: boolean;
  borderSize?: string;
  circleSize?: string | number;
  disabled?: boolean;
  color?: string;
  fill?: string;
  className?: string;
  hoverBackground?: string;
}

const BaseIcon = styled.div<BaseIconProps>`
  display: block;
  cursor: pointer;
  width: ${({ size, circle }) => (size || 1) * (circle ? 0.55 : 1)}em;
  height: ${({ size, circle }) => (size || 1) * (circle ? 0.55 : 1)}em;
  padding: ${({ circle, circleSize }) => (circle ? circleSize || 0.25 : 0)}em;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};

  border-radius: 50%;
  ${({ circle, borderSize, theme }) => circle === true && `border: ${borderSize || '1'}px solid ${theme.colors.gray};`}
  ${({ circle, background, theme }) =>
    (circle === 'background' || background) &&
    `background: ${background || theme.colors.gray};`}

  & rect,
  line,
  polyline,
  path,
  polygon {
    stroke: ${({ color, theme }) => color || theme.colors.primary};
    ${({ fill }) => 'fill: ' + fill};
  }

  :hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }

  svg {
    vertical-align: super;
  }
`;

const makeIcon = (Icon: FunctionComponent): FunctionComponent<BaseIconProps> => props => (
  <BaseIcon {...props}>
    <Icon />
  </BaseIcon>
);

export const Heart = makeIcon(heart);
export const Camera = makeIcon(camera);
export const CameraWithHeart = makeIcon(cameraWithHeart);
export const Bag = makeIcon(bag);
export const Chat = makeIcon(chat);
export const Share = makeIcon(share);
export const ShowRooms = makeIcon(showrooms);
export const Link = makeIcon(link);
export const Eye = makeIcon(eye);
export const Calendar = makeIcon(calendar);
export const Meetings = makeIcon(meetings);
export const Cart = makeIcon(cart);
export const Document = makeIcon(document);
export const SwapCamera = makeIcon(swapCamera);
export const Close = makeIcon(close);
export const CamOn = makeIcon(camOn);
export const CamOff = makeIcon(camOff);
export const MicOn = makeIcon(micOn);
export const MicOff = makeIcon(micOff);
export const Email = makeIcon(email);
export const Avatar = makeIcon(avatar);
export const Plus = makeIcon(plus);
export const Time = makeIcon(time);
export const ThumbsUp = makeIcon(thumbsUp);
export const ChargeCard = makeIcon(chargeCard);
export const Gear = makeIcon(gear);
export const Trash = makeIcon(trash);
export const Checkmark = makeIcon(checkmark);
