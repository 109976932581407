import styled from 'styled-components';
import React, { ChangeEventHandler } from 'react';
import Flex from './Flex';

const TextFieldField = styled.input`
  border: none;
  height: 2em;
  font-size: 1.35em;
  font-family: sans-serif;
  font-weight: lighter;
  background: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgb(206, 206, 206);
  }
  :-ms-input-placeholder {
    color: rgb(206, 206, 206);
  }
`;

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: any;
  className?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const TextField = (props: TextFieldProps) => {
  return (
    <Flex className={props.className} alignItems="center">
      {props.icon && <props.icon style={{ color: 'white', marginRight: '1rem' }} />}
      <TextFieldField {...props} className="" />
    </Flex>
  );
};

export default TextField;
