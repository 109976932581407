import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMeetingsContext from '../../../hooks/useMeetingsContext';
import useShowroomsContext from '../../../hooks/useShowroomsContext';
import moment, { Moment } from 'moment';
import { Meeting as IMeeting } from '../../../hooks/useApi';
import Flex from '../../../components/Flex';
import BaseTextField from '../../../components/TextField';
import Datetime from 'react-datetime';
import './react-datetime.css';
import ReactDatetimeClass from 'react-datetime';
import { Link, useHistory } from 'react-router-dom';

export const ScrollContainer = styled.div`
  font-family: sans-serif;
  background: white;
  height: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  min-height: 100%;
`;

export const ActionButtons = styled(Flex)`
  color: ${({ theme }) => theme.colors.primary};
  padding: 2rem 2rem 0 2rem;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: lighter;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
`;

const HeaderWithBottomLine = styled(Header)`
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 1rem;
`;

const Label = styled.h5`
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 0 2rem 0.8rem 2rem;
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const DatetimePickerName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  width: 4em;
  font-size: 1.1rem;
  font-weight: lighter;
`;

interface DatetimePickerProps extends ReactDatetimeClass.DatetimepickerProps {
  name: String;
  style?: object;
}

const DatetimePicker = styled((props: DatetimePickerProps) => {
  const { className, style, ...allProps } = props;
  let inputProps = {
    size: 25,
  };

  return (
    <Flex className={className} style={style} alignItems={'center'}>
      <DatetimePickerName>{allProps.name}</DatetimePickerName>
      <Datetime {...allProps} inputProps={inputProps} dateFormat="MMMM D, YYYY, " timeFormat="h:mm A" />
    </Flex>
  );
})`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const TextField = styled(BaseTextField)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  margin-top: 10px;
`;

const Section = ({
  title,
  children,
  labelStyle,
  containerStyle,
}: {
  title: string;
  children: React.ReactNode;
  labelStyle?: object;
  containerStyle?: object;
}) => {
  return (
    <React.Fragment>
      <Label style={labelStyle}>{title}</Label>
      <div style={{ ...containerStyle, paddingLeft: '2rem', paddingRight: '2rem' }}>{children}</div>
    </React.Fragment>
  );
};

const ScheduleConfirmationText = styled.div`
  padding: 1rem;
  text-align: center;
`;

const FinishScheduling = ({ numericAlias }: { numericAlias: number }) => {
  return (
    <React.Fragment>
      <ActionButtons justifyContent={'space-between'}>
        <span />
        <Link to={'/meetings/current'}>Done</Link>
      </ActionButtons>
      <HeaderWithBottomLine>Share Invite</HeaderWithBottomLine>
      <ScheduleConfirmationText>
        Great! Your invite is ready, you can share it with other just by giving them this link:
        <br />
        <br />
        <Link to={'/invites/?invite=' + numericAlias}>
          https://{window.location.host + '/invites/?invite=' + numericAlias}
        </Link>
      </ScheduleConfirmationText>
    </React.Fragment>
  );
};

const ScheduleAppointment = () => {
  const history = useHistory();
  const { createMeeting } = useMeetingsContext();
  const { showrooms, createShowroom } = useShowroomsContext();
  const [title, setTitle] = useState<string>('');
  const [guestEmailAddress, setGuestEmailAddress] = useState<string>('');
  const [guestPhoneNumber, setGuestPhoneNumber] = useState<string>('');
  const [startDate, setStartDate] = useState<Moment | string>(moment());
  const [endDate, setEndDate] = useState<Moment | string>(moment().add(1, 'hour'));
  //const [showroom, setShowroom] = useState<string>('create');
  const [showroomName, setShowroomName] = useState<string>('');
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    if (typeof startDate === 'object') {
      setEndDate(moment(startDate).add(1, 'hour'));
    }
  }, [startDate]);

  const [created, setCreated] = useState<IMeeting | null>(null);

  const onSubmit = () => {
    if (created) {
      history.push('/meetings/current');
    }

    if (typeof startDate === 'string' || typeof endDate === 'string') {
      return;
    }

    const existingShowroom = showrooms.find(showroom => {
      if (showroom.title === showroomName) return true;
    });

    if (existingShowroom) {
      createMeeting(existingShowroom.id, title, startDate, endDate).then(setCreated);
    } else {
      createShowroom(showroomName)
        .then(showroom => createMeeting(showroom.id, title, startDate, endDate))
        .then(setCreated)
        .catch(setError);
    }
  };

  const requiredFieldsNotEmpty = () => {
    return showroomName && title;
  };

  const urlChunks = history.location.pathname.split('/');
  urlChunks.pop();
  const cancelUrl = urlChunks.join('/');

  return (
    <ScrollContainer>
      <Container>
        {!created && (
          <React.Fragment>
            <ActionButtons justifyContent={'space-between'}>
              <a onClick={() => history.push(cancelUrl)}>Cancel</a>
              {requiredFieldsNotEmpty() ? (
                <a onClick={onSubmit}>Schedule</a>
              ) : (
                <span style={{ color: '#CCC' }}>Schedule</span>
              )}
            </ActionButtons>
            <Header>Appointment Invite</Header>

            <Section title={'SHOWROOM'}>
              {/** @TODO Uncomment the code below to return the room creation back */}
              {/* <select onChange={e => setShowroom(e.target.value)}>
                <option value={'create'}>Create New Showroom</option>
                {showrooms.map(showroom => (
                  <option key={showroom.id} value={showroom.id}>
                    {showroom.title}
                  </option>
                ))}
              </select>
              {showroom === 'create' && (
                <React.Fragment>
                  <TextField
                    placeholder={'New Showroom Name'}
                    value={showroomName}
                    onChange={e => setShowroomName(e.target.value)}
                  />
                </React.Fragment>
              )} */}
              <TextField value={title} onChange={e => setTitle(e.target.value)} placeholder={'Guest Name'} />
              <TextField
                value={showroomName}
                onChange={e => setShowroomName(e.target.value)}
                placeholder={'ShowRoom Name'}
              />
              <TextField
                value={guestEmailAddress}
                onChange={e => setGuestEmailAddress(e.target.value)}
                placeholder={'Guest Email Address'}
              />
              <TextField
                value={guestPhoneNumber}
                onChange={e => setGuestPhoneNumber(e.target.value)}
                placeholder={'Guest Phone Number'}
              />
            </Section>

            <Section
              title={'TIME'}
              labelStyle={{ marginTop: '2.5rem', marginBottom: '1rem' }}
              containerStyle={{ marginBottom: '5rem' }}
            >
              <DatetimePicker name={'Starts >'} value={startDate} onChange={setStartDate} />
              <DatetimePicker name={'Ends >'} value={endDate} onChange={setEndDate} style={{ marginTop: '1.2rem' }} />
            </Section>
          </React.Fragment>
        )}
        {created && <FinishScheduling numericAlias={created?.numeric_alias!} />}
      </Container>
    </ScrollContainer>
  );
};

export default ScheduleAppointment;
