import styled from 'styled-components';
import React, { ChangeEventHandler } from 'react';
import Flex from '../../../components/Flex';

const TextFieldField = styled.input`
  border: none;
  height: 2em;
  font-size: 1.25em;
  color: white;
  background: none;
  &::placeholder {
    color: white;
  }
`;

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: any;
  className?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const TextField = styled((props: TextFieldProps) => {
  return (
    <Flex className={props.className} alignItems="center">
      <props.icon style={{ color: 'white', marginRight: '1rem' }} />
      <TextFieldField {...props} className="" />
    </Flex>
  );
})`
  margin-bottom: 1em;
  border-bottom: 1px dotted white;
`;

export default TextField;
