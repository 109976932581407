import Flex from '../../components/Flex';
import Logo from '../../components/Logo';
import MailIcon from '@material-ui/icons/MailOutlined';
import PasswordIcon from '@material-ui/icons/LockOutlined';
import Button from '../../components/Button';
import React, { useState } from 'react';
import TextField from './components/TextField';
import useAuthenticationContext from '../../hooks/useAuthenticationContext';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import LinkText from '../../components/LinkText';

const ErrorPanel = styled.div``;

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signIn } = useAuthenticationContext();

  const [status, setStatus] = useState<'processing' | Error | null>(null);

  const onSubmit = () => {
    setStatus('processing');
    signIn(email, password)
      .then(() => {
        setStatus(null);
        let redirectURI = localStorage.getItem('redirectURI') || '/meetings/current';
        localStorage.removeItem('redirectURI');
        history.push(redirectURI);
      })
      .catch(resp => {
        switch (resp.status) {
          case 401:
            setStatus(new Error('Invalid email or password'));
            break;
          default:
            console.error('Unhandled login error ', resp);
            setStatus(new Error('Failed to sign in'));
        }
      });
  };

  return (
    <Flex direction="column">
      <Flex justifyContent="center" style={{ margin: '2em 2em 0 2em' }}>
        <Logo />
      </Flex>
      <Flex direction="column" justifyContent="center" alignItems="center" style={{ margin: '2em 2em 0 2em' }}>
        {status instanceof Error && <ErrorPanel>{(status as Error).message}</ErrorPanel>}
        <TextField
          icon={MailIcon}
          placeholder={'Email'}
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={status === 'processing'}
        />
        <TextField
          icon={PasswordIcon}
          placeholder={'Password'}
          type={'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          disabled={status === 'processing'}
        />
        <Button style={{ marginTop: '1em' }} onClick={() => onSubmit()} disabled={status === 'processing'}>
          {status === 'processing' ? 'Signing In...' : 'Sign In'}
        </Button>
        <LinkText to="/register/customer" style={{ marginTop: '1em' }}>
          Don't have an account?
        </LinkText>
      </Flex>
    </Flex>
  );
};

export default Login;
