import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background: white;
  padding: 0.75em;
  border-radius: 0.5em;
  font-size: 1.25rem;
  color: #429ffb;
`;

export default Button;
