import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useMeetingContext from './useMeetingContext';

export default function useParticipants() {
  const { room } = useMeetingContext();
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  useEffect(() => {
    if (room.participants) {
      setParticipants(Array.from(room.participants.values()));
    }
  }, [room.state, room.participants]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
}
