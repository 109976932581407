import styled from 'styled-components';

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  direction?: 'row' | 'column';
  justifyContent?: 'none' | 'center' | 'space-between' | 'space-around' | 'flex-end';
  alignItems?: 'none' | 'center';
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
`;

Flex.defaultProps = {
  direction: 'row',
  justifyContent: 'none',
  alignItems: 'none',
};

export default Flex;
