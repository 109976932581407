import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Flex from '../../components/Flex';
import BaseTextField from '../../components/TextField';
import { Eye } from '../../components/icons';
import useMeetingsContext from '../../hooks/useMeetingsContext';

const Container = styled.div`
  background: rgb(255, 255, 255, 0.8);
  border-radius: 20px;
  width: 400px;
  height: 320px;
  margin: 0 auto;
  padding: 2rem 0;
  font-size: 1.2rem;
  font-family: sans-serif;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.3rem;
  font-weight: lighter;
  font-family: sans-serif;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
`;

const TextField = styled(BaseTextField)`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: white;
  margin-top: 10px;
`;

const EyeButton = styled(Eye)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 2rem;
  margin-top: 1rem;
`;

const Error = styled.div`
  padding: 0.3rem;
  height: 1rem;
  color: red;
  font-size: 1rem;
`;

const JoinAppointment = () => {
  const [link, setLink] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { joinMeeting } = useMeetingsContext();
  const history = useHistory();

  const commonProps = {
    size: 6,
    circle: true,
    borderSize: '0',
    color: 'white',
  };

  const join = () => {
    if (link) {
      try {
        let url = new URL(link);
        let meetingIDAlias = url.searchParams.get('invite');

        if (meetingIDAlias) {
          joinMeeting(meetingIDAlias)
            .then(() => {
              history.push('/meetings/current');
            })
            .catch(error => {
              if (error.message === 'owner') {
                setError('You are the owner of the meeting');
                return;
              }
            });
        }
      } catch (err) {
        setError('Invalid URL');
        return;
      }
    }
  };

  return (
    <Container>
      <Flex alignItems={'center'} direction={'column'}>
        <Header>Join Appointment</Header>
        Enter or Paste ShowRoom link:
        <TextField value={link} onChange={e => setLink(e.target.value)} />
        <Error>{error}</Error>
        <EyeButton {...commonProps} onClick={join} />
      </Flex>
    </Container>
  );
};

export default JoinAppointment;
