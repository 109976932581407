import React from 'react';
import MeetingPreview from './MeetingPreview';
import Meeting from './Meeting';
import useMeetingContext from '../../../hooks/useMeetingContext';

const StartMeeting = () => {
  const { isMediaConfigured } = useMeetingContext();

  return <>{!isMediaConfigured ? <MeetingPreview onClose={() => {}} /> : <Meeting />}</>;
};

export default StartMeeting;
