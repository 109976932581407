import React from 'react';
import styled from 'styled-components';
import { ParticipantTracks } from './ParticipantsStrip';
import useMeetingContext from '../../../hooks/useMeetingContext';
import useAuthenticationContext from '../../../hooks/useAuthenticationContext';
import useParticipants from '../../../hooks/useParticipants';

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);

  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
`;

const NoRoomOwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  text-align: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: 2rem;
`;
const FocusedParticipant = () => {
  const { mainScreenParticipantId, room } = useMeetingContext();

  /** we need this string to rerender  this component when the owner refreshes the page */
  const participants = useParticipants();
  const { user } = useAuthenticationContext();
  let focusedParticipant = null;

  if (mainScreenParticipantId === Number(user?.id)) {
    focusedParticipant = room.localParticipant;
  } else if (room && room.participants) {
    for (let participant of room.participants.values()) {
      if (Number(participant.identity) === mainScreenParticipantId) {
        focusedParticipant = participant;
      }
    }
  }

  return (
    <Container>
      {focusedParticipant && <ParticipantTracks participant={focusedParticipant} dominant={true} />}
      {focusedParticipant === null && <NoRoomOwnerContainer>The room owner will join soon</NoRoomOwnerContainer>}
    </Container>
  );
};

export default FocusedParticipant;
