import React, { useEffect, useState } from 'react';
import useApi, { Meeting, Showroom } from '../hooks/useApi';
import moment, { Moment } from 'moment';
import { uniqBy } from 'lodash';
import useAuthenticationContext from '../hooks/useAuthenticationContext';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';

interface MeetingsContext {
  meetings: Array<Meeting>;
  createMeeting: (showroom: string, name: string, startDate: Moment, endDate: Moment) => Promise<Meeting>;
  getMeeting: (id: string) => Promise<Meeting>;
  deleteMeeting: (id: string) => Promise<any>;
  joinMeeting: (id: string) => Promise<any>;
  checkMeetingInProgress: (meetings: MeetingWithShowroom[]) => void;
}

export const MeetingsContext = React.createContext<MeetingsContext>(null!);

export interface MeetingWithShowroom extends Meeting {
  showroom?: Showroom;
}

const mapMeetingStartEndDates = (_meeting: Meeting) => {
  const meeting = { ..._meeting };
  const localOffset = moment().utcOffset();
  meeting.start_time = moment(meeting.start_time)
    .add(localOffset, 'm')
    .toISOString();
  meeting.end_time = moment(meeting.end_time)
    .add(localOffset, 'm')
    .toISOString();
  return meeting;
};

const MeetingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    meetings: { get, create, getAll, delete: _delete },
    invites: { join },
  } = useApi();
  const { user } = useAuthenticationContext();
  const [meetings, setMeetings] = useState<Array<MeetingWithShowroom>>([]);
  const history = useHistory();

  const createMeeting = (showroom: string, name: string, startDate: Moment, endDate: Moment) =>
    create(showroom, name, startDate.toISOString(), endDate.toISOString())
      .then(resp => resp.json())
      // .then(mapMeetingStartEndDates)
      .then(meeting => {
        setMeetings([...meetings, meeting]);
        return meeting;
      });

  const getMeeting = async (id: string): Promise<Meeting> => {
    const meeting = meetings.find(m => '' + m.id === id);

    if (!meeting) {
      return get(id)
        .then(resp => resp.json())
        .then(meeting => {
          setMeetings([...meetings, meeting]);
          return meeting;
        });
    }

    return meeting;
  };

  const deleteMeeting = (id: string): Promise<any> => {
    return _delete(id).then(() => setMeetings([...meetings].filter(meeting => meeting.id !== id)));
  };

  const joinMeeting = (id: string): Promise<any> => {
    return join(id)
      .then(resp => resp.json())
      .then(meeting => {
        if (meeting.user_id === user!.id) {
          throw new Error('owner');
        }
        // setMeetings([...meetings, meeting]);
        getMeetings().then(meetings => {
          checkMeetingInProgress(meetings);
        });

        return meeting;
      });
  };

  const checkMeetingInProgress = (meetings: MeetingWithShowroom[]) => {
    const meetingInProgress = meetings.find(meeting => {
      return moment().isBetween(meeting.start_time, meeting.end_time);
    });

    if (meetingInProgress) {
      confirmAlert({
        title: 'You have a meeting in progress',
        message: 'Would you like to join it?',
        buttons: [
          { label: 'Cancel', onClick: () => {} },
          { label: 'Join', onClick: () => history.push('/meeting/' + meetingInProgress.id) },
        ],
      });
    }
  };

  const getMeetings = () => {
    return (
      getAll()
        .then(resp => resp.json())
        .then(meetings => uniqBy(meetings, 'id'))
        // .then(meetings => meetings.map(mapMeetingStartEndDates))
        .then(meetings => {
          setMeetings(meetings);
          return meetings;
        })
    );
  };

  useEffect(() => {
    getMeetings().then(meetings => {
      // checkMeetingInProgress(meetings);
    });
  }, [getAll, history]);

  return (
    <MeetingsContext.Provider
      value={{
        meetings,
        createMeeting,
        getMeeting,
        deleteMeeting,
        joinMeeting,
        checkMeetingInProgress,
      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};

export default MeetingsContextProvider;
