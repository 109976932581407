import React from 'react';
import HP01 from '../../../assets/HP01.jpg';
import HP02 from '../../../assets/HP02.jpg';
import HP04 from '../../../assets/HP04.jpg';
import HP05 from '../../../assets/HP05.jpg';
import { Route, useHistory } from 'react-router-dom';
import Onboarding, { ReviewPage } from './Onboarding';

const steps: Array<ReviewPage> = [
  {
    img: HP01,
    title: 'Live Video Commerce',
    subtitle: 'ShopTime allows Retailers to engage directly with their customers using live video chat.',
  },
  {
    img: HP02,
    title: 'Interactive Live Shopping',
    subtitle:
      'Find a retailer, schedule a live meeting, and then be guided virtually around their store. You can take photos, ask questions, and like/buy the items directly in the app',
  },
  {
    img: HP05,
    title: 'Live Personal Shopper',
    subtitle: 'Think of it as your own private personal shopper. Websites can’t do that!',
  },
  {
    img: HP04,
    title: 'The Future of Retail',
    subtitle:
      'Can’t leave the house? Don’t like to shop online? Hate the crowds? No problem, the future of shopping is here.',
  },
];

const CustomerOnboarding = () => {
  const history = useHistory();

  return (
    <Route path="/onboarding/customer/:step">
      {({ match }) => (
        <Onboarding
          type="customer"
          steps={steps}
          step={parseInt(match!!.params.step)}
          goToStep={(step: number) => history.replace('/onboarding/customer/' + step)}
        />
      )}
    </Route>
  );
};

export default CustomerOnboarding;
