import useMeetingContext from './useMeetingContext';
import { useCallback } from 'react';
import useApi from './useApi';
import useShowroomContext from './useShowroomContext';
import { refreshItemsListEventCreator } from '../utils/dataChannelEvents';

const useTakeSnapshot = () => {
  const { items } = useApi();
  const { videoRef, meeting, emmitDataChannelEvent } = useMeetingContext();
  const { refreshItems } = useShowroomContext();

  const createItem = useCallback(
    (image: Blob) =>
      items.createWithImage(meeting!!.room_id, 'New Item', image).then(resp => {
        emmitDataChannelEvent(refreshItemsListEventCreator());
        refreshItems();
        return resp;
      }),
    [meeting, items]
  );

  return useCallback(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!!;

    let height, width, x, y;
    if (video.videoHeight > video.videoWidth) {
      height = video.videoWidth;
      width = video.videoWidth;
      x = 0;
      y = (video.videoHeight - video.videoWidth) / 2;
    } else {
      height = video.videoHeight;
      width = video.videoHeight;
      x = (video.videoWidth - video.videoHeight) / 2;
      y = 0;
    }

    canvas.height = height;
    canvas.width = width;
    ctx.clearRect(0, 0, width, height);

    ctx.drawImage(video, x, y, width, height, 0, 0, width, height);
    return new Promise<Blob | null>(resolve => canvas.toBlob(blob => resolve(blob), 'image/jpeg', 1.0)).then(blob => {
      if (blob) {
        return createItem(blob);
      }

      throw new Error('no blob');
    });
  }, [createItem, videoRef]);
};

export default useTakeSnapshot;
