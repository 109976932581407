import React from 'react';
import styled from 'styled-components';
import { Plus } from '../../../components/icons';
import { useParams, useHistory } from 'react-router-dom';

const Fab = styled(({ className }: { className?: string }) => {
  const iconProps = {
    size: 4,
    circle: true,
    borderSize: '0',
    circleSize: 0.55,
    color: 'white',
  };
  const history = useHistory();
  const params = useParams() as any;

  return (
    <Plus
      {...iconProps}
      className={className}
      onClick={() => history.push(`/meetings/${params?.view}/add`, { from: history.location.pathname })}
    />
  );
})`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  right: 1rem;
  bottom: 5rem;
`;

export default Fab;
