import useAuthenticationContext from './useAuthenticationContext';
import { APIResponse, authenticatedApiFetch, User, TwilioTokenInfo } from '../api/ShowtimeAPI';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const useApiCall = () => {
  const { token } = useAuthenticationContext();

  const history = useHistory();

  return useCallback(
    <T>(endpoint: string, options: RequestInit, setContentType?: boolean) =>
      new Promise<T>((resolve, reject) => {
        if (!token) {
          history.push('/login');
          return reject();
        }

        const call = authenticatedApiFetch<T>(endpoint, options, token, setContentType).catch(err => {
          // Handle unauthorized
          if (err.status === 401) {
            history.push('/login');
            reject();
          }
          throw err;
        });

        resolve(call);
      }),
    [token, history]
  );
};

export interface Meeting {
  body: any;
  room_id: string;
  created_at: string;
  id: string;
  start_time: string;
  end_time: string;
  name: string;
  updated_at: string;
  numeric_alias: number;
  user_id: number;
}

export interface Snapshot {
  body: string;
  created_at: Date;
  favorited: boolean;
  id: number;
  image: string;
  interested: boolean;
  material: string;
  name: string;
  notes: string;
  price: string;
  room_id: string;
  title: string;
  type: string;
  updated_at: Date;
  size: string;
  color: string;
  brand: string;
}

export interface Showroom {
  id: string;
  title: string;
  owner: User;
  twilio: TwilioTokenInfo;
}

export type CreateRoomResponse = APIResponse<Meeting>;

type GetRoomResponse = APIResponse<Meeting>;
type GetRoomsResponse = APIResponse<Array<Meeting>>;

type GetSnapshotsResponse = APIResponse<Array<Snapshot>>;
type GetSnapshotResponse = APIResponse<Snapshot>;

export type CreateItemResponse = APIResponse<Snapshot>;

interface Api {
  meetings: {
    create: (showroom: string, name: string, startDate: string, endDate: string) => Promise<CreateRoomResponse>;
    get: (room: string) => Promise<GetRoomResponse>;
    getAll: () => Promise<APIResponse<Array<Meeting>>>;
    delete: (id: string) => Promise<any>;
  };
  items: {
    create: (room: string, name: string) => Promise<CreateItemResponse>;
    createWithImage: (room: string, name: string, image: Blob) => Promise<CreateItemResponse>;
    getAll: (room: string) => Promise<GetSnapshotsResponse>;
    delete: (id: number) => Promise<void>;
    get: (id: string) => Promise<GetSnapshotResponse>;
    update: (item: Snapshot) => Promise<APIResponse<any>>;
  };
  showrooms: {
    create: (title: string) => Promise<APIResponse<Showroom>>;
    getAll: () => Promise<APIResponse<Array<Showroom>>>;
    delete: (id: string) => Promise<void>;
    get: (id: string) => Promise<APIResponse<Showroom>>;
    getMeetings: (id: string) => Promise<APIResponse<Array<Meeting>>>;
  };
  images: {
    create: (id: string, image: Blob) => Promise<APIResponse<{ image: string }>>;
  };
  invites: {
    join: (numeric_id: string) => Promise<APIResponse<any>>;
  };
}

const useApi = (): Api => {
  const fetch = useApiCall();

  return useMemo<Api>(
    () => ({
      meetings: {
        create: (room_id: string, name: string, start_time: string, end_time: string) =>
          fetch<APIResponse<Meeting>>('/meetings', {
            method: 'POST',
            body: JSON.stringify({ name, room_id: +room_id, start_time, end_time }),
          }),
        get: (meeting: string): Promise<GetRoomResponse> => fetch('/meetings/' + meeting, { method: 'GET' }),
        getAll: (): Promise<APIResponse<Array<Meeting>>> => fetch('/meetings', { method: 'GET' }),
        delete: (id: string): Promise<any> => fetch('/meetings/' + id, { method: 'DELETE' }),
      },
      items: {
        create: (room: string, name: string) =>
          fetch<CreateItemResponse>('/rooms/' + room + '/items', { method: 'POST', body: JSON.stringify({ name }) }),
        createWithImage: (room: string, name: string, image: Blob) => {
          const body = new FormData();
          body.append('name', name);
          body.append('image', image);
          return fetch<CreateItemResponse>('/rooms/' + room + '/items', { method: 'POST', body }, false);
        },
        getAll: (room: string): Promise<GetSnapshotsResponse> => fetch('/rooms/' + room + '/items', { method: 'GET' }),
        delete: (id: number) => fetch<void>(`/items/${id}`, { method: 'DELETE' }),
        get: (id: string): Promise<GetSnapshotResponse> => fetch('/items/' + id, { method: 'GET' }),
        update: (item: Snapshot) =>
          fetch<APIResponse<any>>('/items/' + item.id, { method: 'PATCH', body: JSON.stringify(item) }),
      },
      showrooms: {
        create: (title: string) =>
          fetch<APIResponse<Showroom>>('/rooms', { method: 'POST', body: JSON.stringify({ title }) }),
        getAll: () => fetch<APIResponse<Array<Showroom>>>('/rooms', { method: 'GET' }),
        delete: (id: string) => fetch<void>(`/rooms/${id}`, { method: 'DELETE' }),
        get: (id: string) => fetch<APIResponse<Showroom>>(`/rooms/${id}`, { method: 'GET' }),
        getMeetings: () => fetch<APIResponse<Array<Meeting>>>('/meetings', { method: 'GET' }),
      },
      images: {
        create: (id: string, image: Blob) => {
          const body = new FormData();
          body.append('name', 'test');
          body.append('image', image, 'image.jpg');
          return fetch<APIResponse<{ image: string }>>('/items/' + id + '/images', { method: 'POST', body }, false);
        },
      },
      invites: {
        join: (numeric_id: string) => fetch('/invite/' + numeric_id, { method: 'PATCH' }),
      },
    }),
    [fetch]
  );
};

export default useApi;
