import { useCallback, useEffect, useState } from 'react';
import useMeetingContext from './useMeetingContext';
import Video, { CreateLocalTrackOptions, LocalVideoTrackPublication } from 'twilio-video';

export default () => {
  const {
    room: { localParticipant },
  } = useMeetingContext();

  const [videoDevicesCount, setVideoDevicesCount] = useState(0);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => devices.filter(({ kind }) => kind === 'videoinput'))
      .then(devices => {
        setVideoDevicesCount(devices.length);
      });
  }, [localParticipant]);

  const cycle = useCallback(() => {
    const localVideoTrack: LocalVideoTrackPublication | null =
      localParticipant?.videoTracks.size > 0 ? localParticipant.videoTracks.values().next().value : null;

    const findCurrentDeviceIndex = (devices: Array<MediaDeviceInfo>) =>
      localVideoTrack ? devices.findIndex(({ label }) => label === localVideoTrack.track.mediaStreamTrack.label) : null;

    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => devices.filter(({ kind }) => kind === 'videoinput'))
      .then(devices => {
        if (devices.length <= 1) {
          return devices[0];
        }

        const currentDeviceIndex = findCurrentDeviceIndex(devices);

        if (currentDeviceIndex === null || currentDeviceIndex === devices.length - 1) {
          return devices[0];
        }

        return devices[currentDeviceIndex + 1];
      })
      .then(device => {
        if (localVideoTrack) {
          localVideoTrack.track.stop();
          const localTrackPublication = localParticipant.unpublishTrack(localVideoTrack.track);
          localParticipant.emit('trackUnpublished', localTrackPublication);
        }

        const options: CreateLocalTrackOptions = {
          width: 1280,
          height: 720,
          resizeMode: 'none',
          name: `camera-${Date.now()}`,
          deviceId: { exact: device.deviceId },
        };

        return Video.createLocalVideoTrack(options);
      })
      .then(track => localParticipant.publishTrack(track));
  }, [localParticipant]);

  return { cycle, videoDevicesCount };
};
