import styled from 'styled-components';
import Text from '../../../components/Text';
import useShowroomContext from '../../../hooks/useShowroomContext';
import React, { useEffect } from 'react';
import { Item } from '../common/Showroom';
import useMeetingContext from '../../../hooks/useMeetingContext';
import useDataChannelEventHandler from '../../../hooks/useDataChannelEventHandler';
import { ChangeMainParticipantEventPayload, DataChannelEvents } from '../../../utils/dataChannelEvents';

const ItemListContainer = styled.div<{ count: number; open: boolean }>`
  position: absolute;
  bottom: 0;
  transform: translateY(${({ open }) => (open ? '0' : '100%')});
  transition: 0.25s;
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 8em);
  min-height: 8em;
  grid-gap: 1em;
  overflow-x: scroll;
  width: 100vw;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
`;

const ItemListStatusText = styled(Text)`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const SliderItem = styled(Item)`
  width: 8rem;
  height: 8rem;
`;

const ItemsSlider = ({ open }: { open: boolean }) => {
  const { setShowroomViewState } = useMeetingContext();
  const { items, refreshItems } = useShowroomContext();

  useDataChannelEventHandler(DataChannelEvents.REFRESH_ITEMS_LIST, (sourceUserId, payload: null) => {
    refreshItems();
  });

  return (
    <ItemListContainer count={items?.length || 0} open={open}>
      {items ? (
        items.length !== 0 ? (
          items.map(item => <SliderItem onClick={item => setShowroomViewState(item.id)} key={item.id} item={item} />)
        ) : (
          <ItemListStatusText>This showroom doesn't have any items yet</ItemListStatusText>
        )
      ) : (
        <ItemListStatusText>Loading showroom items list...</ItemListStatusText>
      )}
    </ItemListContainer>
  );
};

export default ItemsSlider;
