import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
  height: 100%;
`;

//Set scroll only on some main views
const MainView = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>;
};

export default MainView;
