import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import LandingRouter from './landing/LandingRouter';
import MainRouter from './main/MainRouter';
import AuthenticationContextProvider from '../state/AuthenticationContext';
import useAuthenticationContext from '../hooks/useAuthenticationContext';

const Redirector = () => {
  const { token } = useAuthenticationContext();

  if (token) {
    return <Redirect to="/meetings/current" />;
  } else {
    return <Redirect to="/login" />;
  }
};

const Router = () => {
  return (
    <BrowserRouter>
      <AuthenticationContextProvider>
        <Route path="/" exact>
          <Redirector />
        </Route>
        <LandingRouter />
        <MainRouter />
      </AuthenticationContextProvider>
    </BrowserRouter>
  );
};

export default Router;
