import styled from 'styled-components';
import useLocalVideoToggle from '../../../../hooks/useLocalVideoToggle';
import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle';
import useCameraCycle from '../../../../hooks/useCameraCycle';
import Color from 'color';
import { SwapCamera, CamOff, CamOn, Close, MicOff, MicOn } from '../../../../components/icons';
import React from 'react';
import useMeetingContext from '../../../../hooks/useMeetingContext';

const SecondaryVideoControlsContainer = styled.div<{ show: boolean }>`
  position: absolute;
  right: 1em;
  top: 60%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translateY(-50%) translateX(${({ show }) => (show ? '0' : 'calc(100% + 1em)')});
  transition: 0.5s;

  z-index: 1;

  & > :not(:first-child) {
    margin-top: 0.8em;
  }
`;

const SecondaryVideoControls = ({ show }: { show: boolean; hide: () => void }) => {
  const [video, toggleVideoEnabled] = useLocalVideoToggle();
  const [audio, toggleAudioEnabled] = useLocalAudioToggle();
  const { cycle, videoDevicesCount } = useCameraCycle();
  const { onLeaveMeeting } = useMeetingContext();

  const commonProps = {
    size: 3,
    circle: true,
    borderSize: '0',
    circleSize: 0.55,
    color: 'white',
    background: Color('black')
      .fade(0.6)
      .string(),
    hoverBackground: Color('grey')
      .fade(0.6)
      .string(),
  };

  const disabledControlColor = Color('red')
    .fade(0.4)
    .string();

  const hoverDisabledBackground = Color('red')
    .fade(0.7)
    .string();

  return (
    <SecondaryVideoControlsContainer show={show}>
      <Close
        {...commonProps}
        onClick={onLeaveMeeting}
        background={Color('white')
          .fade(0.1)
          .string()}
        color="red"
      />
      {audio ? (
        <MicOn {...commonProps} onClick={toggleAudioEnabled} />
      ) : (
        <MicOff
          {...commonProps}
          hoverBackground={hoverDisabledBackground}
          background={disabledControlColor}
          onClick={toggleAudioEnabled}
        />
      )}
      {video ? (
        <CamOn {...commonProps} onClick={toggleVideoEnabled} />
      ) : (
        <CamOff
          {...commonProps}
          hoverBackground={hoverDisabledBackground}
          background={disabledControlColor}
          onClick={toggleVideoEnabled}
        />
      )}
      {videoDevicesCount > 1 && <SwapCamera {...commonProps} onClick={() => cycle()} />}
    </SecondaryVideoControlsContainer>
  );
};

export default SecondaryVideoControls;
