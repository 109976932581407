import React, { useEffect, useState } from 'react';
import { Link, Route, useRouteMatch, useHistory, Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import color from 'color';
import { Calendar, Eye, Close } from '../../components/icons';
import Flex from '../../components/Flex';
import HeaderBar, { HeaderBarWithAvatar } from '../../components/HeaderBar';
import Meetings from './common/Meetings';
import BottomAppNavigation from '../../components/BottomAppNavigation';
import Text from '../../components/Text';
import TextField from '../../components/TextField';
import SlidingRoute from '../../components/SlidingRoute';
import { VideoProvider } from '../../state/VideoContext';
import useMeetingsContext from '../../hooks/useMeetingsContext';
import MeetingsContextProvider from '../../state/MeetingsContext';
import MeetingContextProvider from '../../state/MeetingContext';
import { Meeting as IMeeting } from '../../hooks/useApi';
import useShowroomsContext from '../../hooks/useShowroomsContext';
import ShowroomsContextProvider from '../../state/ShowroomsContext';
import Showrooms from './common/Showrooms';
import Showroom from './common/Showroom';
import ShowroomItem from './common/ShowroomItem';
import ScheduleAppointment from './ScheduleAppointment/ScheduleAppointment';
import NewMeetingButton from './meeting/NewMeetingButton';
import Invites from './Invites';
import JoinAppointment from './JoinAppointment';
import Profile from './profile/Profile';
import ShoppingCart from '../main/common/ShoppingCart';
import ShowroomContextProvider from '../../state/ShowroomContext';
import MainView from '../../components/MainView';
import Video from 'twilio-video';
import StartMeeting from './meeting/StartMeeting';

const AddOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: ${({ theme }) =>
    color(theme.colors.primary)
      .fade(0.2)
      .string()};
  z-index: 1000000;

  display: flex;
  flex-direction: column;
`;

const OverlayButton = styled(Link)`
  width: 5em;
  padding-left: 1em;
  padding-right: 1em;

  color: white;
  text-decoration: none;
`;

const OverlayButtonCircle = styled.div`
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
`;

const OverlayButtonText = styled(Text)`
  color: white;
  font-size: 0.8em;
  text-align: center;
`;

const ScheduleButton = ({ to }: { to: string }) => {
  return (
    <OverlayButton to={to}>
      <OverlayButtonCircle>
        <Calendar size={3} color={'white'} />
      </OverlayButtonCircle>
      <OverlayButtonText>Schedule New Meeting</OverlayButtonText>
    </OverlayButton>
  );
};

const JoinButton = ({ to }: { to: string }) => {
  return (
    <OverlayButton to={to}>
      <OverlayButtonCircle>
        <Eye size={3} color={'white'} />
      </OverlayButtonCircle>
      <OverlayButtonText>Join an Appointment</OverlayButtonText>
    </OverlayButton>
  );
};

const AddOverlayButtons = styled.div`
  position: absolute;
  width: 100%;
  top: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const AddOverlayCloseButton = styled.div`
  padding: 1rem;
  font-size: 2em;
  font-family: sans-serif;
  color: white;
`;

const ScheduleDialogContainer = styled.div`
  flex-grow: 1;
  background: white;
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  padding-left: 1em;
  font-family: 'Roboto Light', sans-serif;
  & > * {
    margin-bottom: 0.25em;
  }
`;

const BrowserNotSupportedContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const AddMeetingRouter = () => {
  const history = useHistory();

  const match = useRouteMatch<{ view: string }>('/meetings/:view/**');
  const [urlBeforeDialogAppear, setUrlBeforeDialogAppear] = useState<string>('');

  useEffect(() => {
    const urlChunks = history.location.pathname.split('/');
    urlChunks.pop();
    setUrlBeforeDialogAppear(urlChunks.join('/'));
  }, []);

  const closeIconProps = {
    size: 3,
    circle: true,
    borderSize: '0',
    circleSize: 0.55,
    color: 'white',
  };

  return (
    <AddOverlay>
      <Flex justifyContent="flex-end">
        <AddOverlayCloseButton onClick={() => history.push(urlBeforeDialogAppear)}>
          <Close {...closeIconProps} />
        </AddOverlayCloseButton>
      </Flex>
      <Route path={'/meetings/:view/add'} exact={true}>
        <AddOverlayButtons>
          <JoinButton to={'/meetings/' + match?.params.view + '/join'} />
          <ScheduleButton to={'/meetings/' + match?.params.view + '/schedule'} />
        </AddOverlayButtons>
      </Route>
      <Route path={'**/join'}>
        <JoinAppointment />
      </Route>
    </AddOverlay>
  );
};

const MainRouter = () => {
  return !Video.isSupported ? (
    <Route path={['/meetings/**', '/showroom*', '/meeting*', '/invites', '/schedule', '/profile']}>
      <BrowserNotSupportedContainer>
        <div>Your browser doesn't support video connection. Please, choose different browser.</div>
      </BrowserNotSupportedContainer>
    </Route>
  ) : (
    <Route path={['/meetings/**', '/showroom*', '/meeting*', '/invites', '/schedule', '/profile']}>
      <ShowroomsContextProvider>
        <MeetingsContextProvider>
          <Invites />

          <SlidingRoute enterDirection={'up'} exitDirection={'down'} path={['/meetings/:v/add', '/meetings/:v/join']}>
            <AddMeetingRouter />
          </SlidingRoute>

          <SlidingRoute enterDirection={'up'} exitDirection={'down'} path={'/meetings/:v/schedule'}>
            <ScheduleAppointment />
          </SlidingRoute>

          <Route path={['/meetings', '/showrooms', '/showroom/:id']}>
            <Route path={['/meetings/:view?/:action?']}>
              <Flex direction="column" style={{ height: '100%' }}>
                <HeaderBarWithAvatar title="Appointments" />
                <MainView>
                  <Meetings />
                </MainView>
                <NewMeetingButton />
                <BottomAppNavigation />
              </Flex>
            </Route>

            <Route path={['/showrooms']} exact={true}>
              <Flex direction="column" style={{ height: '100%' }}>
                <HeaderBarWithAvatar title="Showrooms" />
                <MainView>
                  <Showrooms />
                </MainView>
                <BottomAppNavigation />
              </Flex>
            </Route>

            <SlidingRoute enterDirection={'up'} exitDirection={'down'} path={'/showrooms/:id'} timeout={1} exact={true}>
              {({ match }) =>
                match?.isExact && (
                  <ShowroomContextProvider id={match.params.id}>
                    <Showroom />
                  </ShowroomContextProvider>
                )
              }
            </SlidingRoute>

            {/* <SlidingRoute enterDirection={'up'} exitDirection={'down'} path={'/showrooms/:id'} timeout={1} exact={true}></SlidingRoute> */}

            <Route path={'/showrooms/:id/items/:item'} exact={true}>
              {({ match }) =>
                match?.isExact && (
                  <Flex direction="column" style={{ height: '100%' }}>
                    <HeaderBar title="Item Information" backUrl={'/showrooms/' + match.params.id} />
                    <MainView>
                      <ShowroomItem id={match?.params.item} />
                    </MainView>
                    {/* <BottomShowRoomNavigation showRoomId={match.params.id} /> */}
                  </Flex>
                )
              }
            </Route>

            <Route path={['/showrooms/:id/cart']} exact={true}>
              {({ match }) =>
                match?.isExact && (
                  <Flex direction="column" style={{ height: '100%' }}>
                    <HeaderBar title="Shopping Cart" backUrl={'/showrooms/' + match.params.id} />
                    <ShowroomContextProvider id={match.params.id}>
                      <ShoppingCart />
                    </ShowroomContextProvider>
                  </Flex>
                )
              }
            </Route>
          </Route>

          <Route path="/meeting/:id">
            <VideoProvider>
              <MeetingContextProvider>
                <StartMeeting />
              </MeetingContextProvider>
            </VideoProvider>
          </Route>

          <SlidingRoute path="/profile" enterDirection={'up'} exitDirection={'down'}>
            <Profile />
          </SlidingRoute>
        </MeetingsContextProvider>
      </ShowroomsContextProvider>
    </Route>
  );
};

export default MainRouter;
