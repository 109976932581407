import { Redirect, Route } from 'react-router-dom';
import React, { useState } from 'react';
import useAuthenticationContext from '../../hooks/useAuthenticationContext';
import useMeetingsContext from '../../hooks/useMeetingsContext';

const Invites = () => {
  const [status, setStatus] = useState<null | 'joining' | 'joined' | 'not-found' | 'error' | 'owner'>(null);
  const { loggedIn } = useAuthenticationContext();
  const { joinMeeting } = useMeetingsContext();

  return (
    <Route path={['/invites']} exact>
      {match => {
        if (!loggedIn) {
          localStorage.setItem('redirectURI', match.location.pathname + match.location.search);
          return <Redirect to={'/login'} />;
        }
        if (!match.match) return null;

        const params = new URLSearchParams(match.location.search);

        const id = params.get('invite');
        if (!id) {
          return <div>Invalid URL</div>;
        }

        if (status === null) {
          setStatus('joining');
          joinMeeting(id)
            .then(() => setStatus('joined'))
            .catch(error => {
              if (error === 'owner') {
                setStatus('owner');
                return;
              }
              setStatus('error');
            });
        }

        if (status === 'joining') {
          return <span>processing invite...</span>;
        }

        if (status === 'owner') {
          return <span>Great, your invite URL works, you can now share it with others.</span>;
        }

        if (status === 'error') {
          return (
            <span>
              The invite is invalid, It may have been deleted by the organizer or the organizer no longer exists.
            </span>
          );
        }

        if (status === 'joined') {
          return <Redirect to={'/meetings/current'} />;
        }
      }}
    </Route>
  );
};

export default Invites;
