import React, { useState, createContext, ReactNode } from 'react';
import { CreateLocalTrackOptions, LocalAudioTrack, LocalDataTrack, LocalVideoTrack } from 'twilio-video';
import useLocalTracks from '../hooks/useLocalTracks';

export interface IVideoContext {
  localTracks: Array<LocalAudioTrack | LocalVideoTrack | LocalDataTrack>;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack | null>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack | null>;
  getLocalDataTrack: () => LocalDataTrack | null;
  cameraDisabled: boolean;
  setCameraDisabled: (state: boolean) => void;
  microphoneDisabled: boolean;
  setMicrophoneDisabled: (state: boolean) => void;
}

export const VideoContext = createContext<IVideoContext>(null!);

export const VideoProvider = ({ children }: { children: ReactNode }) => {
  const { localTracks, getLocalVideoTrack, getLocalAudioTrack, getLocalDataTrack } = useLocalTracks();
  const [cameraDisabled, setCameraDisabled] = useState<boolean>(false);
  const [microphoneDisabled, setMicrophoneDisabled] = useState<boolean>(false);

  return (
    <VideoContext.Provider
      value={{
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        getLocalDataTrack,
        cameraDisabled,
        setCameraDisabled,
        microphoneDisabled,
        setMicrophoneDisabled,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
