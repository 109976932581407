import React, { FunctionComponent, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Text from '../../../components/Text';
import { Avatar, BaseIconProps, ChargeCard, Close, Email, Gear, ThumbsUp } from '../../../components/icons';
import useAuthenticationContext from '../../../hooks/useAuthenticationContext';
import Flex from '../../../components/Flex';
import HeaderBar from '../../../components/HeaderBar';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  padding-left: 1em;
  padding-right: 1em;
`;

const SectionLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: bolder;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Section = styled(({ label, className, children }: { label: string; className?: string; children: ReactNode }) => {
  return (
    <div className={className}>
      <SectionLabel>{label}</SectionLabel>
      <ul>{children}</ul>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  & ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    & > li:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    }
    & > li:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
    & > li:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }
  }
`;

const Button = styled(
  ({
    className,
    text,
    Icon,
    onClick,
  }: {
    className?: string;
    text: string;
    Icon: FunctionComponent<BaseIconProps>;
    onClick?: () => void;
  }) => {
    const theme = useContext(ThemeContext);

    return (
      <li className={className} onClick={onClick}>
        <Text color={theme.colors.appleGray}>{text}</Text>
        <Icon size={2} color={theme.colors.appleGray} />
      </li>
    );
  }
)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 1em;
  color: ${({ theme }) => theme.colors.appleGray};
  cursor: pointer;
  transition: 150ms;
  &:hover {
    transition: 150ms;
    background-color: ${({ theme }) => theme.colors.appleGray5};
  }
`;

const AvatarSection = styled(({ className }: { className?: string }) => {
  const theme = useContext(ThemeContext);
  const { user } = useAuthenticationContext();
  return (
    <div className={className}>
      <Avatar style={{ marginLeft: '-10px' }} size={5} color={theme.colors.appleGray} />
      <Text color={'black'}>{user?.name}</Text>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2em 1em;
`;

const LogoutButton = styled(({ className }: { className?: string }) => {
  const theme = useContext(ThemeContext);
  const { logout: _logout } = useAuthenticationContext();
  const history = useHistory();
  const logout = () => {
    _logout();
    history.push('/login');
  };

  return (
    <Flex
      className={className}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      onClick={logout}
    >
      <Text color={theme.colors.primary}>Logout</Text>
      <Close size={2} />
    </Flex>
  );
})`
  margin-left: 1em;
  margin-right: 1em;
`;

const Profile = () => {
  const history = useHistory();
  const showNotImplementedAlert = () =>
    alert("Hi there, this isn't implemented yet.\nThanks for helping test the beta");
  return (
    <>
      <HeaderBar title={''}>
        <Close onClick={() => history.goBack()} />
      </HeaderBar>
      <Container>
        <AvatarSection />
        <Section label={'support'}>
          <Button text={'email us'} Icon={Email} onClick={showNotImplementedAlert} />
          <Button text={'beta feedback'} Icon={ThumbsUp} onClick={showNotImplementedAlert} />
        </Section>
        <Section label={'account settings'}>
          <Button text={'profile'} Icon={Avatar} onClick={showNotImplementedAlert} />
          <Button text={'payment & billing'} Icon={ChargeCard} onClick={showNotImplementedAlert} />
          <Button text={'Settings'} Icon={Gear} onClick={showNotImplementedAlert} />
        </Section>
        <LogoutButton />
      </Container>
    </>
  );
};

export default Profile;
