import { useContext } from 'react';
import { ShowroomsContext } from '../state/ShowroomsContext';

export default function useShowroomsContext() {
  const context = useContext(ShowroomsContext);
  if (!context) {
    throw new Error('useShowroomsContext must be used within a ShowroomsContextProvider');
  }
  return context;
}
