import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import useApi, { Snapshot } from '../../../hooks/useApi';
import useShowroomContext from '../../../hooks/useShowroomContext';
import MainView from '../../../components/MainView';

const BottomCartContainer = styled(Flex)`
  padding: 1.5rem 1em;
  background: rgb(96, 157, 245);
  background: linear-gradient(90deg, rgba(96, 157, 245, 1) 0%, rgba(115, 215, 195, 1) 100%);
  color: white;
  font-family: sans-serif;
`;

const CheckoutButton = styled.button`
  border: none;
  background: white;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-family: sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemName = styled.div`
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 600;
`;

const ItemField = styled.div`
  font-family: sans-serif;
  font-size: 0.8rem;
  color: rgb(146, 146, 146);
`;

const ItemPrice = styled.div`
  font-family: sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  position: absolute;
  bottom: 1rem;
`;

const QuantityBadge = styled.div`
  padding: 0.2rem 0.5rem;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50%;
  background: rgb(224, 224, 220);
`;

const CartItem = ({ item }: { item: Snapshot }) => {
  return (
    <Flex
      direction="row"
      style={{ padding: '1rem 0 1rem 1rem', borderBottom: '1px solid #ccc', justifyContent: 'space-between' }}
    >
      <img src={item.image} alt="" style={{ width: '8rem', height: '8rem', borderRadius: '0.5rem' }} />
      <div style={{ flex: 2, padding: '0.4rem 1rem', position: 'relative' }}>
        <ItemName>{item.name}</ItemName>
        <ItemField>SKU:</ItemField>
        <ItemField>Size:</ItemField>
        <ItemPrice>
          {new Intl.NumberFormat('en-EN', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            useGrouping: false,
          }).format(Number(item.price))}
        </ItemPrice>
      </div>
      <div>
        <QuantityBadge>1</QuantityBadge>
      </div>
    </Flex>
  );
};

const calculateTotalPrice = (cartItems: Snapshot[]) => {
  if (cartItems.length === 0) return 0;

  return cartItems.reduce((accumulator, currentValue) => {
    return (accumulator += Number(currentValue.price));
  }, 0);
};

const ShoppingCart = () => {
  const { roomId } = useShowroomContext();
  const [cartItems, setCartItems] = useState<Snapshot[]>([]);

  const {
    items: { getAll },
  } = useApi();

  useEffect(() => {
    if (roomId) {
      getAll(roomId)
        .then(resp => resp.json())
        .then(items => {
          return items.filter(item => {
            return item.interested === true;
          });
        })
        .then(items => {
          setCartItems(items);
        });
    }
  }, [roomId]);

  let totalPrice = calculateTotalPrice(cartItems);

  return (
    <>
      <MainView>
        {cartItems.map((item: Snapshot) => (
          <CartItem key={item.id} item={item} />
        ))}
      </MainView>
      <BottomCartContainer justifyContent="space-around" alignItems="center">
        <span style={{ flex: 1, fontSize: '0.8rem' }}>
          Total <br /> {cartItems.length} items{' '}
        </span>
        <span style={{ flex: 4, textAlign: 'center', fontSize: '1.2rem' }}>
          {new Intl.NumberFormat('en-EN', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            useGrouping: false,
          }).format(totalPrice)}{' '}
          USD
        </span>
        <span style={{ flex: 1, textAlign: 'right' }}>
          <CheckoutButton>Checkout &gt;</CheckoutButton>
        </span>
      </BottomCartContainer>
    </>
  );
};

export default ShoppingCart;
