import React, { useState } from 'react';
import { signIn, User } from '../api/ShowtimeAPI';

interface AuthenticationContext {
  loggedIn: boolean;
  user: User | null;
  token: string | null;
  signIn: (email: string, password: string) => Promise<any>;
  logout: () => void;
}

export const AuthenticationContext = React.createContext<AuthenticationContext>(null!);

const AuthenticationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(
    localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null
  );

  const _signIn = (email: string, password: string) =>
    signIn(email, password)
      .then(resp => resp.json())
      .then(data => {
        setUser(data.user);
        setToken(data.authorization.bearer);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('token', data.authorization.bearer);
        return data;
      });

  const logout = () => {
    setToken(null);
    setUser(null);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        loggedIn: token !== null,
        user,
        token,
        signIn: _signIn,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
