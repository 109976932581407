import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Onboarding from './Onboarding';
import { ENTERED, ENTERING, EXITED, EXITING, TransitionStatus } from 'react-transition-group/Transition';
import { Transition } from 'react-transition-group';
import Register from './Register';
import Login from './Login';
import OnboardingRouter from './onboardings/OnboardingRouter';
import SlidingRoute from '../../components/SlidingRoute';

const backgroundClipperTransformation = (status: TransitionStatus) => {
  switch (status) {
    case ENTERING:
      return '4em';
    case ENTERED:
      return '4em';
    case EXITING:
      return '100vh';
    case EXITED:
      return '100vh';
  }
};

const gettingStartedAnimatedTransformation = (status: TransitionStatus) => {
  switch (status) {
    case EXITING:
      return 'translateY(-100vh)';
    case EXITED:
      return 'translateY(-100vh)';
    case ENTERING:
      return 'translateX(0)';
    case ENTERED:
      return 'translateX(0)';
  }
};

const backgroundGradientHeight = (status: TransitionStatus) => {
  switch (status) {
    case ENTERING:
      return '200vw';
    case ENTERED:
      return '200vw';
    case EXITING:
      return '200vh';
    case EXITED:
      return '200vh';
  }
};

const backgroundGradientTransformation = (status: TransitionStatus) => {
  switch (status) {
    case ENTERED:
      return 'rotateZ(-90deg)';
    case ENTERING:
      return 'rotateZ(-90deg)';
    case EXITING:
      return 'rotateZ(0deg)';
  }
};

const BackgroundClipper = styled.div<{ status: TransitionStatus }>`
  position: absolute;
  width: 100%;
  height: ${({ status }) => backgroundClipperTransformation(status)};
  transition: 300ms;
  z-index: -1;
  overflow: hidden;
`;

const BackgroundGradient = styled.div<{ status: TransitionStatus }>`
  position: absolute;
  width: 200%;
  height: ${({ status }) => backgroundGradientHeight(status)};
  background: linear-gradient(180deg, ${props => props.theme.colors.primary} 5%, rgba(0, 218, 195, 1) 100%);
  transform: translateX(-25%) translateY(-25%) ${({ status }) => backgroundGradientTransformation(status)};
  transition: 300ms;
  z-index: -1;
`;

const Background = ({ a }: { a: boolean }) => {
  return (
    <Transition timeout={300} in={a}>
      {status => (
        <React.Fragment>
          <BackgroundClipper status={status}>
            <BackgroundGradient status={status} />
          </BackgroundClipper>
        </React.Fragment>
      )}
    </Transition>
  );
};

const GettingStartedWrapper = (props: { className?: string; status: TransitionStatus }) => {
  return (
    <div className={props.className}>
      <Onboarding />
    </div>
  );
};

const GettingStartedAnimated = styled(GettingStartedWrapper)`
  transform: ${({ status }) => gettingStartedAnimatedTransformation(status)};
  transition: 300ms;
`;

interface RootContainerProps {
  className?: string;
  header: boolean;
  children: React.ReactNode;
}

const ContentContainer = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const Container = (props: RootContainerProps) => {
  return (
    <div className={props.className}>
      <Background a={props.header} />
      <ContentContainer>{props.children}</ContentContainer>
    </div>
  );
};

interface LandingRouterRouteParams {
  view: string | undefined;
  step: string | undefined;
}

const LandingRouter = () => {
  const match = useRouteMatch<LandingRouterRouteParams>('/onboarding/:view/:step');

  const view = match?.params.view!!;

  return (
    <Route path={['/onboarding/**', '/register/**', '/login']}>
      <Container header={view === 'retailer' || view === 'customer'}>
        <Route path="/onboarding/:step">
          {({ match }) => (
            <Transition timeout={300} in={match !== null} unmountOnExit>
              {status => <GettingStartedAnimated status={status} />}
            </Transition>
          )}
        </Route>
        <OnboardingRouter />
        <SlidingRoute enterDirection={'down'} exitDirection={'right'} path="/register/:type">
          <Register />
        </SlidingRoute>
        <SlidingRoute enterDirection={'right'} exitDirection={'right'} path="/login">
          <Login />
        </SlidingRoute>
      </Container>
    </Route>
  );
};

export default LandingRouter;
