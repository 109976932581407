import styled, { ThemeContext } from 'styled-components';
import Flex from '../../../../components/Flex';
import React, { useContext, useState } from 'react';
import useTakeSnapshot from '../../../../hooks/useTakeSnapshot';
import useMeetingContext from '../../../../hooks/useMeetingContext';
import { Camera, CameraWithHeart } from '../../../../components/icons';
import Color from 'color';

const MainControlsContainer = styled.div<{ itemListVisible: boolean }>`
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: center;

  transform: translateY(${({ itemListVisible }) => (itemListVisible ? '0' : '170%')});

  bottom: 10em;
  z-index: 1;
  transition: 0.25s;
`;

const Controls = styled(Flex)`
  padding: 0.2em;
  border-radius: 50px;
  border: 1px solid #c5c5c5;
`;

const MainVideoControls = styled(
  ({ className, itemListVisible, disabled }: { className?: string; itemListVisible: boolean; disabled: boolean }) => {
    const theme = useContext(ThemeContext);
    const takeSnapshot = useTakeSnapshot();
    const { isOwner } = useMeetingContext();

    const [locked, setLocked] = useState(false);

    const _takeSnapshot = () => {
      if (locked) {
        return;
      }

      setLocked(true);
      takeSnapshot()?.finally(() => {
        setLocked(false);
      });
    };

    const Icon = isOwner ? Camera : CameraWithHeart;
    // @ts-ignore
    const color = Color(theme.colors.white)
      .fade(0.6)
      .string();

    return (
      <MainControlsContainer itemListVisible={itemListVisible} className={className}>
        <Controls alignItems="center">
          <Icon
            onClick={() => _takeSnapshot()}
            size={4}
            circleSize={'1'}
            borderSize={'0'}
            color={'white'}
            background={color}
            circle={true}
            disabled={locked}
          />
        </Controls>
      </MainControlsContainer>
    );
  }
)``;

export default MainVideoControls;
