import moment from 'moment';
import { Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import Text from '../../../components/Text';
import Flex from '../../../components/Flex';
import UserAvatar from '../../../components/UserAvatar';
import LinkButton from '../../../components/LinkButton';
import { Route, useHistory } from 'react-router-dom';
import useMeetingsContext from '../../../hooks/useMeetingsContext';
import { Chat, Link as LinkIcon, Share, ShowRooms, Eye, Email } from '../../../components/icons';
import { MeetingWithShowroom } from '../../../state/MeetingsContext';
// @ts-ignore
import { ICalendar } from 'datebook';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useAuthenticationContext from '../../../hooks/useAuthenticationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RowDateText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 0.75em;
`;

const BuyerMainRowActions = styled(Flex)``;

const BuyerMainRowCenter = styled(Flex)`
  flex-grow: 1;
`;

const BuyerMainRowUserName = styled(Text)`
  color: black;
  font-size: 1.25em;
`;

const BuyerMainRowContainer = styled(Flex)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const RowUserAvatar = styled(UserAvatar)`
  padding-right: 0.5em;
  width: 3em;
  height: 3em;
`;

const RowActionButton = styled(Text)`
  color: black;
  font-size: 0.75em;
`;

const ListModeSwitchButton = styled(LinkButton).withConfig({
  shouldForwardProp: prop => prop === 'to' || prop === 'children',
})<{ active: boolean }>`
  box-sizing: border-box;
  display: inline-block;
  border: none;
  background: white;
  font-size: 0.9rem;
  text-decoration: none;
  font-family: sans-serif;

  width: 100%;
  color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.gray)};
  text-align: center;
  padding: 0.9em;
  border-bottom: 1px solid ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.gray)};
  border-radius: 0;
`;

const ListModeSwitch = () => {
  return (
    <Flex>
      <Route path="/meetings/current">
        {({ match }) => (
          <ListModeSwitchButton to="/meetings/current" active={match !== null}>
            Current
          </ListModeSwitchButton>
        )}
      </Route>
      <Route path="/meetings/past">
        {({ match }) => (
          <ListModeSwitchButton to="/meetings/past" active={match !== null}>
            Past
          </ListModeSwitchButton>
        )}
      </Route>
    </Flex>
  );
};

const Row = ({ id, name, start_time, end_time, showroom, numeric_alias, user_id }: MeetingWithShowroom) => {
  const { deleteMeeting } = useMeetingsContext();
  const { user } = useAuthenticationContext();

  const generateICS = useCallback(() => {
    const calendar = new ICalendar({
      start: start_time,
      end: end_time,
      title: 'Shoptime Meeting: ' + name,
      url: window.location.host + '/meeting/' + id,
    });
    calendar.download();
  }, [end_time, id, name, start_time]);

  const generateEmail = useCallback(() => {
    const subject = 'Shoptime Invite - ' + name;
    const body = `
Hey, it’s ${user?.name}!\n
We’re super-excited to have you join us in our virtual showroom where we’ll [give your invitees a call to action here]. The fun kicks off at ${moment(
      start_time
    )} and should last about ${moment.duration(moment(end_time).diff(start_time)).asHours()}.\n
RSVP and get setup on Shoptime Live, our virtual showroom partner, by clicking on the link below:\n
http://${window.location.host}/invites/?invite=${numeric_alias}\n
Can’t wait to see you then!\n
Thanks,\n
${user?.name}
`;
    window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;
  }, [end_time, name, numeric_alias, start_time, user]);

  const _deleteMeeting = () => {
    confirmAlert({
      message: 'Are you sure, you want to delete ' + name,
      buttons: [
        { label: 'Yes', onClick: () => deleteMeeting(id) },
        { label: 'No', onClick: () => {} },
      ],
    });
  };

  return (
    <BuyerMainRowContainer>
      <RowUserAvatar />
      <BuyerMainRowCenter direction="column">
        <BuyerMainRowUserName>{name}</BuyerMainRowUserName>
        <RowDateText title={start_time + ' - ' + end_time}>
          {moment().isBetween(start_time, end_time) ? 'in-progress' : moment(start_time).fromNow()}
        </RowDateText>
        <Flex>
          <Route path="/meetings/current">
            <RowActionButton onClick={() => _deleteMeeting()}>
              {Number(user!.id) === user_id ? 'Delete' : 'Decline'}
            </RowActionButton>
          </Route>
          <Route path="/meetings/past">
            <RowActionButton>New Meeting</RowActionButton>
            <RowActionButton style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>|</RowActionButton>
          </Route>
        </Flex>
      </BuyerMainRowCenter>
      <BuyerMainRowActions>
        <div style={{ display: 'flex' }}>
          <Route path="/meetings/past">
            <Chat size={2} circle={true} color={'black'} />
          </Route>
          <Route path="/meetings/current">
            <LinkIcon
              size={2}
              circle={true}
              color={'black'}
              onClick={() => {
                navigator.clipboard.writeText(window.location.host + '/invites/?invite=' + numeric_alias);
                toast('Meeting link was copied to clipboard');
              }}
            />
          </Route>
          <Email size={2} style={{ marginLeft: '0.5em' }} circle={true} color={'black'} onClick={generateEmail} />
          <Share
            size={2}
            style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
            circle={true}
            color={'black'}
            onClick={generateICS}
          />
          <Route path="/meetings/past">
            <Link to={'/showrooms/' + id}>
              <ShowRooms size={2} circle={true} color={'black'} />
            </Link>
          </Route>
          <Route path="/meetings/current">
            <Link to={'/meeting/' + id}>
              <Eye size={2} circle={true} color={'black'} />
            </Link>
          </Route>
        </div>
      </BuyerMainRowActions>
    </BuyerMainRowContainer>
  );
};

const ListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  & > *:nth-child(n + 2) {
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
  }
`;

const OverflowContainer = styled.div`
  padding-bottom: 6rem;
`;

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
})`
  .toast {
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
  }

  button[aria-label='close'] {
    display: none;
  }
`;

const Meetings = () => {
  const { meetings } = useMeetingsContext();

  return (
    <React.Fragment>
      <ListModeSwitch />
      <ListContainer>
        <OverflowContainer>
          {meetings ? (
            <React.Fragment>
              <Route path={'/meetings/current'}>
                {meetings
                  .filter(({ end_time }) =>
                    moment(end_time)
                      .add('72', 'hours')
                      .isAfter(moment())
                  )
                  .map(meeting => (
                    <Row key={meeting.id} {...meeting} />
                  ))}
              </Route>
              <Route path={'/meetings/past'}>
                {meetings
                  .filter(({ end_time }) =>
                    moment(end_time)
                      .add('72', 'hours')
                      .isBefore(moment())
                  )
                  .map(meeting => (
                    <Row key={meeting.id} {...meeting} />
                  ))}
              </Route>
            </React.Fragment>
          ) : (
            <span>loading...</span>
          )}
        </OverflowContainer>
      </ListContainer>
      <StyledToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

export default Meetings;
