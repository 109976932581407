import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group/Transition';

type Direction = 'up' | 'down' | 'right' | 'left' | null;

const containerSlidingAnimation = (direction: Direction, state: TransitionStatus) => {
  if (direction === 'right' && state === 'exited') return 'translateX(-100vw)';
  if (direction === 'right' && state === 'exiting') return 'translateX(-100vw)';
  if (direction === 'right' && state === 'entering') return 'translateX(100vw)';
  if (direction === 'right' && state === 'entered') return 'translateX(0)';

  if (direction === 'left' && state === 'exited') return 'translateX(100vw)';
  if (direction === 'left' && state === 'exiting') return 'translateX(100vw)';
  if (direction === 'left' && state === 'entering') return 'translateX(-100vw)';
  if (direction === 'left' && state === 'entered') return 'translateX(0)';

  if (direction === 'up' && state === 'exited') return 'translateY(-100vh)';
  if (direction === 'up' && state === 'exiting') return 'translateY(-100vh)';
  if (direction === 'up' && state === 'entering') return 'translateY(100vh)';
  if (direction === 'up' && state === 'entered') return 'translateY(0)';

  if (direction === 'down' && state === 'exited') return 'translateY(100vh)';
  if (direction === 'down' && state === 'exiting') return 'translateY(100vh)';
  if (direction === 'down' && state === 'entering') return 'translateY(-100vh)';
  if (direction === 'down' && state === 'entered') return 'translateY(0)';
};

const Container = styled.div<{ direction: Direction; state: TransitionStatus }>`
  position: absolute;
  right: 0;
  width: 100%;
  transform: ${({ direction, state }) => containerSlidingAnimation(direction, state)};
  transition: 300ms;
  z-index: 10000;
  height: 100%;
`;

interface SlidingRouteProps extends RouteProps {
  enterDirection: Direction;
  exitDirection: Direction;
  timeout?: number;
}

const SlidingRoute = (props: SlidingRouteProps) => {
  return (
    <Route {...props}>
      {({ match }) => (
        <Transition timeout={props.timeout || 400} in={match !== null} unmountOnExit={true}>
          {state => {
            const direction = state === 'entering' || state === 'entered' ? props.enterDirection : props.exitDirection;
            return (
              <Container state={state} direction={direction}>
                {//prettier-ignore
                //@ts-ignore
                typeof props.children === 'function' ? props.children({ match }) : props.children}
              </Container>
            );
          }}
        </Transition>
      )}
    </Route>
  );
};

export default SlidingRoute;
