import React, { useEffect, useContext, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import useApi, { Snapshot } from '../../../hooks/useApi';
import { Bag, Heart, Checkmark } from '../../../components/icons';
import useShowroomContext from '../../../hooks/useShowroomContext';
import useShowroomsContext from '../../../hooks/useShowroomsContext';
import { ShowroomWithThumbnail } from '../../../state/ShowroomsContext';

import Flex from '../../../components/Flex';
import HeaderBar from '../../../components/HeaderBar';
import MainView from '../../../components/MainView';
import BottomShowRoomNavigation from '../../../components/BottomShowRoomNavigation';

import Color from 'color';

const ItemContainer = styled.div`
  border: 1px solid rgb(171, 171, 171);
  box-sizing: border-box;
  border-radius: 1em;
  overflow: hidden;
  flex: 1;
  height: 10rem;
  width: 10rem;
  max-width: 10rem;
  position: relative;
  flex-basis: auto;
  align-self: flex-start;
  margin: 1rem 1rem 0 0;
`;

export const ItemButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 2;
  justify-content: space-between;
`;

const BackdropImage = styled.img`
  position: absolute;
  width: 100%;
  z-index: -10;
  filter: blur(10px);
  transform: scale(2);
`;

export const ItemIconContainer = styled.div`
  padding: 0.2rem;
`;

export const Item = ({
  item,
  onClick,
  className,
}: {
  item: Snapshot;
  onClick: (item: Snapshot) => void;
  className?: string;
}) => {
  const {
    update,
    roomId,
    selectItemsMode,
    addItemToSelected,
    isItemSelected,
    removeItemFromSelected,
  } = useShowroomContext();
  const theme = useContext(ThemeContext);

  const favorite = () => {
    const entity = { id: item.id, favorited: !item.favorited };
    // @ts-ignore
    update(entity);
  };

  const cart = () => {
    const entity = { id: item.id, interested: !item.interested };
    // @ts-ignore
    update(entity);
  };

  const toggleSelect = (itemId: number) => {
    if (isItemSelected(itemId)) {
      removeItemFromSelected(item.id);
    } else {
      addItemToSelected(item.id);
    }
  };

  const heartBackgroundColor = Color('rgb(214, 74, 237)').string();
  const cartBackgroundColor = Color('rgb(61, 183, 62)').string();
  const checkMarkFillColor = Color('rgb(9, 193, 252)').string();

  const defaultColor = Color(theme.colors.white)
    .fade(0.6)
    .string();

  return (
    <ItemContainer key={item.id} className={className}>
      {selectItemsMode ? (
        <ItemButtonsContainer style={{ flexDirection: 'column' }}>
          <ItemIconContainer style={{ alignSelf: 'flex-end' }}>
            <Checkmark
              circle={true}
              size={3}
              onClick={() => {
                toggleSelect(item.id);
              }}
              borderSize={'0'}
              color={'white'}
              fill={isItemSelected(item.id) ? checkMarkFillColor : defaultColor}
            />
          </ItemIconContainer>
        </ItemButtonsContainer>
      ) : (
        <ItemButtonsContainer>
          <ItemIconContainer>
            <Heart
              circle={true}
              size={3}
              onClick={favorite}
              borderSize={'0'}
              color={'white'}
              background={item.favorited ? heartBackgroundColor : defaultColor}
            />
          </ItemIconContainer>
          <ItemIconContainer>
            <Bag
              circle={true}
              size={3}
              onClick={cart}
              borderSize={'0'}
              color={'white'}
              background={item.interested ? cartBackgroundColor : defaultColor}
            />
          </ItemIconContainer>
        </ItemButtonsContainer>
      )}

      <div
        style={{
          position: 'absolute',
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <BackdropImage src={item.image} />
        <span onClick={selectItemsMode ? () => toggleSelect(item.id) : () => onClick(item)}>
          <img src={item.image} style={{ width: '100%' }} alt="" />
        </span>
      </div>
    </ItemContainer>
  );
};

const Container = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ShowroomItemsList = ({
  onItemClick,
  selectAll,
}: {
  onItemClick: (item: Snapshot) => void;
  selectAll?: boolean;
}) => {
  const { items } = useShowroomContext();

  // @ts-ignore
  return (
    <Container>
      {items.map((item: Snapshot) => (
        <Item onClick={() => onItemClick(item)} key={item.id} item={item} />
      ))}
    </Container>
  );
};

const ShowroomHeaderControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ControlButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  font-family: sans-serif;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ShowroomHeaderControls = () => {
  const history = useHistory();
  const {
    selectItemsMode,
    setSelectItemsMode,
    selectAllItems,
    unselectAllItems,
    areAllItemsSelected,
  } = useShowroomContext();

  const doneSelectingItems = () => {
    unselectAllItems();
    setSelectItemsMode(false);
  };

  return (
    <ShowroomHeaderControlsContainer>
      {!selectItemsMode ? (
        <>
          <ControlButton
            onClick={() => {
              history.push('/showrooms');
            }}
          >
            Done
          </ControlButton>
          <ControlButton
            onClick={() => {
              setSelectItemsMode(true);
            }}
          >
            Select
          </ControlButton>
        </>
      ) : (
        <>
          {areAllItemsSelected() ? (
            <ControlButton onClick={unselectAllItems}>Deselect All</ControlButton>
          ) : (
            <ControlButton onClick={selectAllItems}>Select All</ControlButton>
          )}
          <ControlButton onClick={doneSelectingItems}>Done</ControlButton>
        </>
      )}
    </ShowroomHeaderControlsContainer>
  );
};

const Showroom = () => {
  const history = useHistory();
  const { roomId } = useShowroomContext();

  return <ShowroomItemsList onItemClick={item => history.push('/showrooms/' + roomId + '/items/' + item.id)} />;
};

export default () => {
  const match = useRouteMatch<{ id: string }>('/showrooms/:id');
  if (!match) {
    return <span>Invalid room Id</span>;
  }
  const { selectItemsMode, setSelectItemsMode } = useShowroomContext();
  const { showrooms } = useShowroomsContext();
  const [showroom, setShowroom] = useState<ShowroomWithThumbnail | null>(null);

  useEffect(() => {
    let showroom = showrooms.find(showroom => {
      return showroom.id == match?.params.id;
    });
    if (showroom) setShowroom(showroom);
  }, [showrooms]);

  let title = showroom?.title;

  if (selectItemsMode) {
    title = 'Select Items';
  }

  return (
    <Flex direction="column" style={{ height: '100%' }}>
      <HeaderBar title={title} controls={<ShowroomHeaderControls />}></HeaderBar>
      <MainView>
        <Showroom />
      </MainView>
      <BottomShowRoomNavigation showRoomId={match?.params.id} />
    </Flex>
  );
};
