import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useMeetingContext from '../../../hooks/useMeetingContext';
import { ShowRooms } from '../../../components/icons';
import HeaderBar from '../../../components/HeaderBar';
import { Swipeable } from 'react-swipeable';
import ShowroomContextProvider from '../../../state/ShowroomContext';
import ShowroomView from './ShowroomView';
import SecondaryVideoControls from './controls/SecondaryControls';
import MainVideoControls from './controls/MainControls';
import ItemsSlider from './ItemsSlider';
import ParticipantsStrip from './ParticipantsStrip';
import FocusedParticipant from './FocusedParticipant';
import useDataChannelEventHandler from '../../../hooks/useDataChannelEventHandler';
import { ChangeMainParticipantEventPayload, DataChannelEvents } from '../../../utils/dataChannelEvents';
import { confirmAlert } from 'react-confirm-alert';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
`;

const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%, rgba(255, 255, 255, 0) 100%);
`;

const Meeting = () => {
  const { room, ownerId, meeting, setShowroomViewState, selectMainScreenParticipant } = useMeetingContext();

  const [showControls, setShowControls] = useState(true);

  const [itemListOpen, setItemListOpen] = useState(false);

  const history = useHistory();

  const onSwiped = useCallback(({ dir }) => {
    if (dir === 'Up') {
      setItemListOpen(true);
    }

    if (dir === 'Down') {
      setItemListOpen(false);
    }

    if (dir === 'Left') {
      setShowControls(true);
    }

    if (dir === 'Right') {
      setShowControls(false);
    }
  }, []);

  // Since this hook requires meeting context,
  // it can't be put in the MeetingContext class
  // in this form, so I've decided to just stick it here
  useDataChannelEventHandler(
    DataChannelEvents.CHANGE_MAIN_PARTICIPANT,
    (sourceUserId, payload: ChangeMainParticipantEventPayload) => {
      if (sourceUserId == ownerId) {
        selectMainScreenParticipant(Number(payload));
      }
    }
  );

  useDataChannelEventHandler(
    DataChannelEvents.END_MEETING,
    (sourceUserId, payload: ChangeMainParticipantEventPayload) => {
      if (sourceUserId == ownerId) {
        confirmAlert({
          message: 'The owner has ended the meeting',
          buttons: [{ label: 'Ok', onClick: () => history.push('/meetings/current') }],
        });
      }
    }
  );

  if (!meeting) {
    return <span>loading...</span>;
  }

  return (
    <ShowroomContextProvider id={meeting.room_id}>
      <Container>
        <HeaderBar title={meeting.name || ''} floating={true}>
          <ShowRooms size={3} onClick={() => setShowroomViewState('list')} />
        </HeaderBar>
        <Background />
        <ParticipantsStrip />
        <Swipeable onSwiped={onSwiped} trackMouse={true}>
          <MainVideoControls
            disabled={!room.participants || room.participants.size === 0}
            itemListVisible={itemListOpen}
          />
          <SecondaryVideoControls show={showControls} hide={() => setShowControls(false)} />
          <FocusedParticipant />
        </Swipeable>
        <ItemsSlider open={itemListOpen} />
      </Container>
      <ShowroomView />
    </ShowroomContextProvider>
  );
};

export default Meeting;
