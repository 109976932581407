import React, { useCallback, useEffect, useState } from 'react';
import useApi, { Snapshot } from '../hooks/useApi';

interface ShowroomContext {
  items: Array<Snapshot>;
  roomId: string;
  update: (snapshot: Snapshot) => Promise<any>;
  refreshItems: () => Promise<any>;
  selectItemsMode: boolean;
  setSelectItemsMode: (selectItemsMode: boolean) => void;
  addItemToSelected: (itemId: number) => void;
  isItemSelected: (itemId: number) => boolean;
  removeItemFromSelected: (itemId: number) => void;
  areAllItemsSelected: () => boolean;
  selectAllItems: () => void;
  unselectAllItems: () => void;
  selectedItemsSize: number;
  removeSelectedItems: () => void;
}

export const ShowroomContext = React.createContext<ShowroomContext>(null!);

const ShowroomContextProvider = ({ children, id }: { children: React.ReactNode; id?: string }) => {
  const { getAll, delete: _delete, update: _update } = useApi().items;

  const [items, setItems] = useState<Array<Snapshot>>([]);
  const [roomId, setRoomId] = useState<string>(null!);
  const [selectItemsMode, setSelectItemsMode] = useState<boolean>(false!);

  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set<number>());

  const selectedItemsSize = selectedItems.size;

  const selectAllItems = () => {
    items.map((item: Snapshot) => {
      addItemToSelected(item.id);
    });
  };

  const unselectAllItems = () => {
    items.map((item: Snapshot) => {
      removeItemFromSelected(item.id);
    });
  };

  const areAllItemsSelected = () => {
    return selectedItems.size === items.length && items.length !== 0;
  };

  const addItemToSelected = (itemId: number) => {
    selectedItems.add(itemId);
    setSelectedItems(new Set([...selectedItems]).add(itemId));
  };

  const removeItemFromSelected = (itemId: number) => {
    selectedItems.delete(itemId);
    setSelectedItems(new Set([...selectedItems]));
  };

  const isItemSelected = (itemId: number) => {
    return selectedItems.has(itemId);
  };

  const refreshItems = useCallback(() => {
    if (!id) {
      return Promise.reject();
    }

    return getAll(id)
      .then(resp => resp.json())
      .then(items => items.sort((a, b) => (a.created_at < b.created_at ? 1 : -1)))
      .then(setItems);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    setRoomId(id);
    refreshItems();
  }, [id]);

  const update = useCallback((snapshot: Snapshot) => {
    return _update(snapshot).then(result => {
      setItems(items =>
        items.map(item => {
          if (item.id === snapshot.id) {
            return { ...item, ...snapshot };
          }

          return item;
        })
      );

      return result;
    });
  }, []);

  const removeSelectedItems = () => {
    let removePromises = [];

    for (const itemId of selectedItems) {
      removePromises.push(_delete(itemId));
    }

    Promise.all(removePromises).then(values => {
      setSelectedItems(new Set([]));
      refreshItems();
    });
  };

  return (
    <ShowroomContext.Provider
      value={{
        items,
        roomId,
        update,
        refreshItems,
        selectItemsMode,
        setSelectItemsMode,
        addItemToSelected,
        isItemSelected,
        removeItemFromSelected,
        areAllItemsSelected,
        selectAllItems,
        unselectAllItems,
        selectedItemsSize,
        removeSelectedItems,
      }}
    >
      {children}
    </ShowroomContext.Provider>
  );
};

export default ShowroomContextProvider;
