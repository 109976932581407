import React, { useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';
import { BinaryMessage } from '../utils/dataChannelEvents';

interface DataTrackProps {
  track: IDataTrack;
  onEvent: (event: string, sourceUserId: string, payload: string) => void;
}

export default function DataTrack({ track, onEvent }: DataTrackProps) {
  const onMessage = (data: string) => {
    // support the old code for backwards compatibility -- soon this case will be used for chat...
    if (typeof data === 'string') {
      const { payload, sourceUserId, event } = JSON.parse(data);
      onEvent(event, sourceUserId, payload);
    } else {
      let binary = new BinaryMessage();
      let dataChannelEvent = binary.convertFrom(data);

      if (dataChannelEvent) {
        console.log('received event:', dataChannelEvent);
        onEvent(dataChannelEvent.event, dataChannelEvent.sourceUserId || '0', dataChannelEvent.payload);
      }
    }
  };
  useEffect(() => {
    track.on('message', onMessage);

    return () => {
      track.off('message', onMessage);
    };
  }, [track]);

  return <></>;
}
