import React from 'react';
import Flex from '../../components/Flex';
import LinkButton from '../../components/LinkButton';
import Text from '../../components/Text';
import Logo from '../../components/Logo';
import SlidingRoute from '../../components/SlidingRoute';

const Onboarding = () => {
  return (
    <Flex direction="column">
      <SlidingRoute enterDirection="left" exitDirection="right" path="/onboarding/1">
        <Flex justifyContent="center" style={{ margin: '2em 2em 0 2em' }}>
          <Logo />
        </Flex>
        <Flex alignItems="center" direction="column" style={{ margin: '2em 2em 0 2em' }}>
          <Text>Welcome to ShopTime</Text>
          <Text>Live Video Commerce</Text>
        </Flex>
        <Flex alignItems="center" direction="column" style={{ margin: '2em 2em 0 2em' }}>
          <Text>Are you:</Text>
          <LinkButton to="/onboarding/retailer/1" style={{ margin: '1em 1em 0 1em', width: '100%' }}>
            A Retailer?
          </LinkButton>
          <LinkButton to="/onboarding/customer/1" style={{ margin: '1em 1em 0 1em', width: '100%' }}>
            A Customer?
          </LinkButton>
        </Flex>
      </SlidingRoute>
    </Flex>
  );
};

export default Onboarding;
