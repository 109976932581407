import { DataChannelEvents } from '../utils/dataChannelEvents';
import useMeetingContext from './useMeetingContext';
import { useEffect } from 'react';

const useDataChannelEventHandler = (
  event: DataChannelEvents,
  handler: (sourceUserId: string, payload: any) => void
) => {
  const { dataChannelEventBus } = useMeetingContext();
  useEffect(() => {
    const _handler = ({ sourceUserId, payload }: { sourceUserId: string; payload: any }) =>
      handler(sourceUserId, payload);
    dataChannelEventBus.addListener(event, _handler);
    return () => {
      dataChannelEventBus.removeListener(event, _handler);
    };
  }, [handler]);
};

export default useDataChannelEventHandler;
