import { createGlobalStyle } from 'styled-components';
//@ts-ignore
import MontserratThin from './Montserrat/Montserrat-Thin.ttf';
//@ts-ignore
import MontserratExtraLight from './Montserrat/Montserrat-ExtraLight.ttf';
//@ts-ignore
import MontserratLight from './Montserrat/Montserrat-Light.ttf';
//@ts-ignore
import MontserratRegular from './Montserrat/Montserrat-Regular.ttf';
//@ts-ignore
import MontserratMedium from './Montserrat/Montserrat-Medium.ttf';

export default createGlobalStyle`

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Thin'),
        url(${MontserratThin}) format('truetype');
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Extra Light'),
        url(${MontserratExtraLight}) format('truetype');
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Light'),
        url(${MontserratLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Regular'),
        url(${MontserratRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Medium'),
        url(${MontserratMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    // * {
    //     font-family: Montserrat !important;
    // }
`;
