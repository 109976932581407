import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import { Link } from 'react-router-dom';
import { Share, Camera, Cart, Trash } from './icons';
import useApi from '../hooks/useApi';
import useShowroomContext from '../hooks/useShowroomContext';
import { confirmAlert } from 'react-confirm-alert';

const BottomNavigationContainer = styled(Flex)`
  padding: 0.5em;
  background: rgb(96, 157, 245);
  background: linear-gradient(90deg, rgba(96, 157, 245, 1) 0%, rgba(115, 215, 195, 1) 100%);
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const BottomShowRoomNavigation = ({ showRoomId }: { showRoomId: string }) => {
  const { items } = useApi();
  // const { emmitDataChannelEvent } = useMeetingContext();
  const { refreshItems, selectedItemsSize, removeSelectedItems } = useShowroomContext();

  const createItem = useCallback(
    (image: Blob) =>
      items.createWithImage(showRoomId, 'New Item', image).then(resp => {
        // emmitDataChannelEvent(refreshItemsListEventCreator());
        refreshItems();
        return resp;
      }),
    [items]
  );

  const validateFile = (file: File, allowedExtensions: string[]) => {
    let split = file.name.split('.');
    let type = split[split.length - 1];
    if (!allowedExtensions.includes(type)) {
      alert('File type not allowed. Try one of the following: ' + allowedExtensions.join(', '));
      return false;
    }
    if (file.size / 1024 / 1024 > 30) {
      alert('File too large. File size cannot exceed 30 mb');
      return false;
    }

    return true;
  };

  const removeItems = () => {
    confirmAlert({
      message: 'Would you like to remove selected items?',
      buttons: [
        {
          label: 'Yes',
          onClick: removeSelectedItems,
        },
        { label: 'Cancel', onClick: () => {} },
      ],
    });
  };

  const getData = (e: ChangeEvent, file: File) => {
    if (file && validateFile(file, ['png', 'jpg', 'jpeg'])) {
      return createItem(file);
    }
  };

  return (
    <BottomNavigationContainer justifyContent="space-around" alignItems="center">
      <Share disabled={true} color={'white'} size={3} />

      <FileInput
        type="file"
        name="file"
        id="file"
        onChange={e => {
          if (e?.target?.files?.length !== 0) {
            //@ts-ignore
            getData(e, e.target.files[0]);
          }
        }}
      />
      <label htmlFor="file">
        <Camera color={'white'} size={3} />
      </label>

      <Link to={`/showrooms/${showRoomId}/cart`}>
        {' '}
        <Cart color={'white'} size={3} />{' '}
      </Link>

      {selectedItemsSize > 0 ? (
        <Trash color={'white'} onClick={removeItems} size={3} />
      ) : (
        <Trash disabled={true} color={'white'} size={3} />
      )}
    </BottomNavigationContainer>
  );
};

export default BottomShowRoomNavigation;
