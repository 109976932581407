import { useContext } from 'react';
import { ShowroomContext } from '../state/ShowroomContext';

export default function useShowroomContext() {
  const context = useContext(ShowroomContext);
  if (!context) {
    throw new Error('useShowroomContext must be used within a ShowroomContextProvider');
  }
  return context;
}
