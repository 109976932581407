import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import HP01 from '../../../assets/HP01.jpg';
import HP02 from '../../../assets/HP02.jpg';
import HP03 from '../../../assets/HP03.jpg';
import HP04 from '../../../assets/HP04.jpg';
import Onboarding, { ReviewPage } from './Onboarding';

const steps: Array<ReviewPage> = [
  {
    img: HP01,
    title: 'Live Video Commerce',
    subtitle: 'ShopTime allows Retailers to engage directly with their customers using live video chat.',
  },
  {
    img: HP02,
    title: 'Interactive Selling',
    subtitle:
      'Schedule a meeting with a customer, and then show them virtually around your store—allowing them to take photos and like/buy the items they’re interested in.',
  },
  {
    img: HP03,
    title: 'Better Than a Website',
    subtitle:
      'Interact with your customers live, answer common pre-sale questions, provide alternatives, offer bundles and discounts.',
  },
  {
    img: HP04,
    title: 'The Future of Retail',
    subtitle:
      'Delight your customers by providing them with the future of retailing, all in the comfort of their homes.',
  },
];

const RetailerOnboarding = () => {
  const history = useHistory();

  return (
    <Route path="/onboarding/retailer/:step">
      {({ match }) => (
        <Onboarding
          type="retailer"
          steps={steps}
          step={parseInt(match!!.params.step)}
          goToStep={(step: number) => history.replace('/onboarding/retailer/' + step)}
        />
      )}
    </Route>
  );
};

export default RetailerOnboarding;
