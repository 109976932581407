import { useContext } from 'react';
import { AuthenticationContext } from '../state/AuthenticationContext';

export default function useAuthenticationContext() {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationProvider');
  }
  return context;
}
