import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import './types';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

Sentry.init({ dsn: 'https://8e6ef981b7bf4545bda165fa393db733@o415140.ingest.sentry.io/5305796' });

ReactDOM.render(<App />, document.getElementById('root'));
