import React from 'react';
import styled from 'styled-components';
import Text from './Text';

const LogoContainer = styled.div`
  display: inline-block;
  padding: 0.5em;
  font-size: 2rem;
  border: 1px solid white;
`;

const Logo = () => (
  <LogoContainer>
    <Text>ShopTime</Text>
  </LogoContainer>
);

export default Logo;
