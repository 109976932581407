import React, { useCallback, useMemo, useEffect } from 'react';
import useParticipants from '../../../hooks/useParticipants';
import useMeetingContext from '../../../hooks/useMeetingContext';
import useAuthenticationContext from '../../../hooks/useAuthenticationContext';
import styled from 'styled-components';
import { LocalTrackPublication, Participant as IParticipant, RemoteTrackPublication } from 'twilio-video';
import Text from '../../../components/Text';
import useTrack from '../../../hooks/useTrack';
import VideoTrack from '../../../components/VideoTrack';
import AudioTrack from '../../../components/AudioTrack';
import DataTrack from '../../../components/DataTrack';
import usePublications from '../../../hooks/usePublications';
import { IVideoTrack } from '../../../types';
import { MicOff, Avatar } from '../../../components/icons';
import Color from 'color';

const VideoTrackContainer = styled.div<{ dominant?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;

  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
`;

export const ParticipantTracks = ({ participant, dominant }: { participant: IParticipant; dominant?: boolean }) => {
  const publications = usePublications(participant);

  const hasVideo = participant.videoTracks.size > 0;

  return (
    <>
      {publications.map(publication => (
        <ParticipantTrack
          dominant={dominant}
          participant={participant}
          key={publication.trackSid}
          publication={publication}
        />
      ))}
      {!hasVideo && <NoVideoBackground></NoVideoBackground>}
    </>
  );
};

const NoVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoVideoBackground = () => {
  const commonProps = {
    size: 4,
    borderSize: '0',
    color: 'white',
  };

  return (
    <NoVideoContainer>
      <Avatar {...commonProps} />
    </NoVideoContainer>
  );
};

const VideoTrackBackgroundContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: scale(3);
  filter: blur(5px);
`;

const VideoTrackBackground = ({ track }: { track: IVideoTrack }) => {
  return (
    <VideoTrackBackgroundContainer>
      <VideoTrack track={track} />
    </VideoTrackBackgroundContainer>
  );
};

const VideoTrackPositionAbsolute = styled(VideoTrack)`
  position: absolute;
`;

export const ParticipantTrack = ({
  publication,
  dominant,
}: {
  publication: LocalTrackPublication | RemoteTrackPublication | undefined;
  participant: IParticipant;
  dominant?: boolean;
}) => {
  const track = useTrack(publication);

  const { videoRef, dataChannelEventBus } = useMeetingContext();

  if (!track) {
    return null;
  }

  if (track.kind === 'video') {
    if (dominant) {
      return <VideoTrack track={track} ref={videoRef} />;
    }

    return (
      <VideoTrackContainer dominant={dominant}>
        <VideoTrackBackground track={track} />
        <VideoTrackPositionAbsolute track={track} />
      </VideoTrackContainer>
    );
  } else if (track.kind === 'audio') {
    return <AudioTrack track={track} />;
  } else if (track.kind === 'data') {
    return (
      <DataTrack
        track={track}
        onEvent={(event, sourceUserId, payload) =>
          dataChannelEventBus.emit(event, { sourceUserId, payload: JSON.parse(payload) })
        }
      />
    );
  } else {
    return null;
  }
};

const ParticipantStatusContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 5px;
`;

export const ParticipantStatus = ({ participant }: { participant: IParticipant }) => {
  const publications = usePublications(participant); // we need this to re-render component once someone switch off the audio

  const hasAudio = participant.audioTracks.size > 0;

  const commonProps = {
    size: 1,
    circle: true,
    borderSize: '0',
    circleSize: 0.25,
    color: 'white',
    background: Color('black')
      .fade(0.6)
      .string(),
  };

  return (
    <>
      <ParticipantStatusContainer>
        <div></div>
        <div>{!hasAudio ? <MicOff {...commonProps} /> : ''}</div>
      </ParticipantStatusContainer>
    </>
  );
};

const ParticipantContainer = styled.div<{ dominant: boolean }>`
  position: relative;
  overflow: hidden;
  outline: 0.15em solid ${({ dominant }) => (dominant ? 'green' : 'white')};
  box-sizing: border-box;
  width: calc(100vh / 17 * 2);
  transition: 1s;
  margin-left: 1em;
`;

const ParticipantIdentity = styled(Text)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: white;
  text-align: center;
  z-index: 10;
`;

const Participant = ({ participant }: { participant: IParticipant }) => {
  const { room, isOwner, selectMainScreenParticipant } = useMeetingContext();
  const dominant = room.dominantSpeaker !== null && room.dominantSpeaker.identity === participant.identity;

  const handleSelectParticipant = () => {
    if (isOwner) selectMainScreenParticipant(Number(participant.identity));
  };

  return (
    <ParticipantContainer dominant={dominant} onClick={handleSelectParticipant}>
      <ParticipantIdentity>{participant.identity}</ParticipantIdentity>
      <ParticipantTracks participant={participant} />
      <ParticipantStatus participant={participant} />
    </ParticipantContainer>
  );
};

const Container = styled.div`
  position: absolute;
  height: calc(100vh / 11 * 2);
  display: flex;
  flex-direction: row;
  top: 6em;
  left: 0.5em;
  z-index: 10;
`;

const ParticipantsStrip = () => {
  const { room, mainScreenParticipantId } = useMeetingContext();
  const { user } = useAuthenticationContext();
  const remoteParticipants = useParticipants();
  const filteredParticipants = remoteParticipants.filter(participant => {
    return Number(participant.identity) !== mainScreenParticipantId;
  });

  const iAmConnected = () => {
    return room && room.localParticipant;
  };

  const iAmOnTheMainScreen = () => {
    return mainScreenParticipantId === Number(user?.id);
  };

  return (
    <Container>
      {iAmConnected() && !iAmOnTheMainScreen() && <Participant participant={room.localParticipant} />}
      {filteredParticipants.map(participant => (
        <Participant key={participant.identity} participant={participant} />
      ))}
    </Container>
  );
};

export default ParticipantsStrip;
