import React from 'react';
import { Route } from 'react-router-dom';
import RetailerOnboarding from './RetailerOnboarding';
import CustomerOnboarding from './CustomerOnboarding';

const OnboardingRouter = () => {
  return (
    <React.Fragment>
      <Route path="/onboarding/retailer/:step">
        <RetailerOnboarding />
      </Route>
      <Route path="/onboarding/customer/:step">
        <CustomerOnboarding />
      </Route>
    </React.Fragment>
  );
};

export default OnboardingRouter;
