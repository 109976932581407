const API_BASE = 'https://shoptime-api.xyz';

const JSON_HEADER = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export class APIResponse<T> extends Response {
  json(): Promise<T> {
    return super.json().then<T>(resp => {
      return resp.json();
    });
  }
}

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface TwilioTokenInfo {
  token: string;
  identity: string;
  room: string;
}

export type SignInResponse = APIResponse<{ authorization: { bearer: string }; user: User }>;

const apiFetch = <T extends unknown>(endpoint: string, options: RequestInit, setContentType?: boolean): Promise<T> => {
  return fetch(API_BASE + endpoint, {
    ...options,
    headers: { ...(setContentType === undefined || setContentType ? JSON_HEADER : {}), ...options.headers },
  }).then(resp => {
    if (!resp.ok) {
      throw resp;
    }

    return resp as T;
  });
};

export const authenticatedApiFetch = <T>(
  endpoint: string,
  options: RequestInit,
  token: string,
  setContentType?: boolean
) =>
  apiFetch<T>(
    endpoint,
    { ...options, headers: { ...options.headers, Authorization: `Bearer ${token}` } },
    setContentType
  );

export const signIn = (email: string, password: string): Promise<SignInResponse> => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  };

  return apiFetch<SignInResponse>('/login', options);
};

export const signUp = (username: string, email: string, password: string) => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      name: username,
      email: email,
      password: password,
    }),
  };

  return apiFetch<any>('/signup', options);
};
