import React from 'react';
import Router from './routes/Router';
import { ThemeProvider } from 'styled-components';
import GlobalFonts from './assets/fonts/fonts';
import GlobalStyles from './assets/global';
import Div100vh from 'react-div-100vh';

const theme = {
  colors: {
    primary: '#00C0FB',
    gray: '#C5C5C5',
    appleGray: '#8e8e93',
    appleGray1: '#aeb0b2',
    appleGray2: '#c7c7cc',
    appleGray3: '#d1d1d6',
    appleGray4: '#e5e5ea',
    appleGray5: '#f2f2f2',
    white: 'white',
  },
  font: {
    family: 'Montserrat',
  },
  paddings: ['0.5rem', '1rem', '2rem'],
};

const App = () => {
  return (
    <Div100vh>
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyles />
        <Router />
      </ThemeProvider>
    </Div100vh>
  );
};

export default App;
