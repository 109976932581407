import EventEmitter from 'events';
import Video, { ConnectOptions, LocalDataTrack, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Callback } from '../types';
import { isMobile } from '../utils';
import useVideoContext from './useVideoContext';

// @ts-ignore
window.TwilioVideo = Video;

export enum ConnectionState {
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
}

export default function useRoom(localTracks: LocalTrack[], onError: Callback) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const { cameraDisabled, microphoneDisabled } = useVideoContext();
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.DISCONNECTED);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const [initialized, setInitialized] = useState<Boolean>(false);
  const [_isOwner, setIsOwner] = useState<Boolean>(false);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
    let requiredTracksArrayLength = 3;

    if (cameraDisabled) requiredTracksArrayLength -= 1;
    if (microphoneDisabled) requiredTracksArrayLength -= 1;

    //We are waiting until all 3 tracks (Audio, Video, Data) will be initialized, and then we publish them after connect is called (it means connectionState is Connected)
    if (
      !initialized &&
      localTracks.length === requiredTracksArrayLength &&
      connectionState === ConnectionState.CONNECTED
    ) {
      localTracksRef.current
        .filter(({ kind }) => !((kind === 'audio' && microphoneDisabled) || (kind === 'video' && cameraDisabled)))
        .forEach(track => {
          // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
          // However, tracks must be published manually in order to set the priority on them.
          // All video tracks are published with 'low' priority. This works because the video
          // track that is displayed in the 'MainParticipant' component will have it's priority
          // set to 'high' via track.setPriority()
          room.localParticipant.publishTrack(track, { priority: _isOwner ? 'high' : 'low' });
        });

      setInitialized(true);
    }
  }, [localTracks, connectionState]);

  const connect = useCallback(
    (token, isOwner) => {
      setConnectionState(ConnectionState.CONNECTING);
      setIsOwner(isOwner);
      let options: ConnectOptions = isOwner
        ? {
            audio: true,
            video: { height: 1080, width: 1920, frameRate: 30 },
            maxVideoBitrate: 8388608,
            maxAudioBitrate: 524288,
            networkQuality: { local: 1, remote: 1 },
            ...(isMobile
              ? {
                  // mobile specific params
                }
              : {
                  // non-mobile (desktop/laptop) specific params
                  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                }),
          }
        : {
            // otherwise not owner
            dominantSpeaker: true,
            bandwidthProfile: {
              video: {
                mode: 'presentation',
                dominantSpeakerPriority: 'high',
                renderDimensions: {
                  high: { height: 720, width: 1280 },
                  standard: { height: 720, width: 1280 },
                  low: { height: 720, width: 1280 },
                },
                // Still increases max allowed bitrate despite of Twilio documentation
                // states, along with below option as well
                maxSubscriptionBitrate: 8388608,
              },
            },
          };
      return Video.connect(token, { ...options, tracks: [] }).then(
        newRoom => {
          setRoom(newRoom);

          // @ts-ignore
          window.twilioRoom = newRoom;

          setConnectionState(ConnectionState.CONNECTED);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            localTracksRef.current.forEach(track => {
              if (!(track instanceof LocalDataTrack)) track.stop();
            });
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          const disconnect = () => newRoom.disconnect();

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }

          return newRoom;
        },
        error => {
          onError(error);
          setConnectionState(ConnectionState.DISCONNECTED);
        }
      );
    },
    [onError]
  );

  return { room, connectionState, connect };
}
