import { Route, useHistory } from 'react-router-dom';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Text from '../../../components/Text';
import Flex from '../../../components/Flex';
import LinkButton from '../../../components/LinkButton';

const headerContainerOpacity = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const HeaderContainer = styled.div`
  min-height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  animation: ${headerContainerOpacity} 300ms;
`;

const Header = ({ type }: { type: 'retailer' | 'customer' }) => {
  const history = useHistory();

  return (
    <HeaderContainer>
      <Text onClick={() => history.replace('/onboarding/1')}>back</Text>
      <Text>ShopTime for {type === 'retailer' ? 'Retailers' : 'Customers'}</Text>
      <Text onClick={() => history.replace('/register/' + type)}>skip</Text>
    </HeaderContainer>
  );
};

const Container = styled(Flex)`
  max-height: 100vh;
`;

const contentContainerTransition = keyframes`
from {
  transform: translateY(200vh);
}
to {
  transform: translateY(0);
}
`;

const ContentContainer = styled(Flex)`
  animation: ${contentContainerTransition} 300ms;
`;

const ReviewImage = styled.img`
  width: 100%;
`;

interface StepIndicatorDotProps {
  selected: boolean;
}

const StepIndicatorDot = styled.div<StepIndicatorDotProps>`
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: ${({ selected }) => (selected ? 'rgba(66,156,251,1)' : 'rgba(202, 202, 202, 1)')};
  margin: 0.5em;
`;

const ReviewStepIndicator = (props: { step: number; steps: number; onGoToStep: (step: number) => void }) => {
  return (
    <Flex justifyContent="center">
      {[...Array(props.steps).keys()].map(i => (
        <StepIndicatorDot key={i} onClick={() => props.onGoToStep(i + 1)} selected={i === props.step - 1} />
      ))}
    </Flex>
  );
};

const ReviewPageContainer = styled(Flex)`
  max-width: 580px;
  margin: 2em auto;
`;

const GradientButton = styled(LinkButton)`
  color: white;
  background: linear-gradient(90deg, ${props => props.theme.colors.primary} 5%, rgba(0, 218, 195, 1) 100%);
`;

export interface ReviewPage {
  img: any;
  title: string;
  subtitle: string;
}

const Onboarding = ({
  type,
  steps,
  step,
  goToStep,
}: {
  type: 'retailer' | 'customer';
  steps: Array<ReviewPage>;
  step: number;
  goToStep: (step: number) => void;
}) => {
  return (
    <Container direction="column">
      <Header type={type} />
      <ContentContainer direction="column" justifyContent="space-between">
        {steps.map(({ title, subtitle, img }, i) => (
          <Route enterDirection={'right'} exitDirection={'right'} key={i} path={'/onboarding/:a/' + (i + 1)}>
            <ReviewPageContainer direction="column" alignItems="center">
              <Text style={{ fontSize: '2em', color: 'rgba(66,156,251,1)' }}>{title}</Text>
              <Text style={{ color: 'gray', margin: '2em', height: '3em', textAlign: 'center' }}>{subtitle}</Text>
              {step === steps.length && <GradientButton to={'/register/' + type}>Let's Get Started!</GradientButton>}
              <ReviewImage src={img} />
            </ReviewPageContainer>
          </Route>
        ))}
        <ReviewStepIndicator onGoToStep={goToStep} step={step} steps={steps.length} />
      </ContentContainer>
    </Container>
  );
};

export default Onboarding;
