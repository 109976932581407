import styled from 'styled-components';
import React, { ChangeEventHandler } from 'react';
import Flex from '../../../components/Flex';

const ShowroomItemTextFieldInput = styled.input`
  grid-column: 2 / 3;
  border: none;
  height: 2em;
  font-size: 1.35em;
  font-family: sans-serif;
  font-weight: lighter;
  background: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgb(206, 206, 206);
  }
  :-ms-input-placeholder {
    color: rgb(206, 206, 206);
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-gap: 16px;
`;

const Label = styled.label`
  font-size: 0.8rem;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
  grid-column: 1 / 2;
  place-self: center end;
  color: ${({ theme }) => theme.colors.primary};
`;

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: any;
  className?: string;
  value?: any;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const ShowroomItemTextField = (props: TextFieldProps) => {
  return (
    <Container className={props.className}>
      {props.label && <Label>{props.label}</Label>}
      <ShowroomItemTextFieldInput {...props} className="" />
    </Container>
  );
};

export default ShowroomItemTextField;
