import React, { useCallback } from 'react';
import { Link, Route } from 'react-router-dom';
import Flex from '../../../components/Flex';
import Text from '../../../components/Text';
import styled from 'styled-components';
import { Document } from '../../../components/icons';
import useShowroomsContext from '../../../hooks/useShowroomsContext';
import { ShowroomWithThumbnail } from '../../../state/ShowroomsContext';
import { confirmAlert } from 'react-confirm-alert';

const RowContainer = styled.div`
  display: flex;
  text-decoration: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const RowTitle = styled(Text)`
  color: black;
  font-size: 1.25em;
`;

const RowActionButton = styled(Text)`
  color: black;
  font-size: 0.75em;
`;

const Thumbnail = ({ url }: { url: string | 'loading' | 'missing' }) => {
  if (url === 'loading') {
    return <span>loading</span>;
  }

  if (url === 'missing') {
    return <Document size={6} />;
  }

  return <img src={url} style={{ width: '6em', height: '6em', borderRadius: '25%' }} />;
};

const Row = ({ id, title, thumbnail }: ShowroomWithThumbnail) => {
  const { delete: _delete } = useShowroomsContext();

  const confirmDelete = useCallback(() => {
    confirmAlert({
      message:
        'If you delete this showroom, you also delete all of these things that are associated with it: ' +
        'all invites to this Showroom, all snaps for this Showroom',
      buttons: [
        { label: 'Delete', onClick: () => _delete(id) },
        { label: 'Cancel', onClick: () => {} },
      ],
    });
  }, []);

  return (
    <RowContainer>
      <Link to={'/showrooms/' + id}>
        <span style={{ paddingRight: '1em' }}>
          <Thumbnail url={thumbnail} />
        </span>
      </Link>
      <Flex direction="column">
        <RowTitle>{title}</RowTitle>
        <RowActionButton onClick={confirmDelete}>Delete</RowActionButton>
      </Flex>
    </RowContainer>
  );
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > *:nth-child(n + 2) {
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
  }
`;

const Showrooms = () => {
  const { showrooms } = useShowroomsContext();

  return (
    <Route path={'/showrooms'}>
      <Container>
        {showrooms.map(showroom => (
          <Row key={showroom.id} {...showroom} />
        ))}
      </Container>
    </Route>
  );
};

export default Showrooms;
