import { LocalVideoTrack } from 'twilio-video';
import { useCallback } from 'react';
import useVideoContext from './useVideoContext';
import useMeetingContext from './useMeetingContext';

export default function useLocalVideoToggle() {
  const { localTracks, getLocalVideoTrack, cameraDisabled, setCameraDisabled } = useVideoContext();
  const {
    room: { localParticipant },
  } = useMeetingContext();
  const videoTrack = !cameraDisabled && (localTracks.find(({ name }) => name.includes('camera')) as LocalVideoTrack);

  const toggleVideoEnabled = useCallback(() => {
    if (videoTrack) {
      setCameraDisabled(true);
      if (localParticipant) {
        try {
          const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
          console.log('publication to remove', localTrackPublication);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit('trackUnpublished', localTrackPublication);
        } catch (e) {
          console.error(e);
        }
      }
      videoTrack.stop();
    } else {
      setCameraDisabled(false);
      getLocalVideoTrack().then((track: LocalVideoTrack | null) => {
        if (!track) {
          // TODO: tell the user that there was no video source detected
          return;
        }
        if (localParticipant) {
          localParticipant.publishTrack(track, { priority: 'low' });
        }
      });
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled] as const;
}
