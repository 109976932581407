import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkText = styled(Link)`
  font-family: sans-serif;
  color: white;
  text-decoration: none;
`;

export default LinkText;
