import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import { Route, Link } from 'react-router-dom';
import { Eye, Meetings, ShowRooms } from './icons';

const BottomNavigationContainer = styled(Flex)`
  padding: 0.5em;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
`;

const BottomAppNavigation = () => {
  return (
    <BottomNavigationContainer justifyContent="space-around" alignItems="center">
      <Route path="/preview*">
        {({ match }) => <Eye disabled={true} active={match !== null} circle={false} size={3} />}
      </Route>
      <Route path="/showrooms*">
        {({ match }) => (
          <Link to={'/showrooms'}>
            <ShowRooms active={match !== null} size={3} />
          </Link>
        )}
      </Route>
      <Route path="/meetings*">
        {({ match }) => (
          <Link to={'/meetings/current'}>
            <Meetings active={match !== null} size={3} />
          </Link>
        )}
      </Route>
    </BottomNavigationContainer>
  );
};

export default BottomAppNavigation;
