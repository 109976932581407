import React, { useCallback, useEffect, useState } from 'react';
import useApi, { Showroom } from '../hooks/useApi';

export interface ShowroomWithThumbnail extends Showroom {
  thumbnail: string | 'loading' | 'missing';
}

interface ShowroomsContext {
  showrooms: Array<ShowroomWithThumbnail>;
  createShowroom: (name: string) => Promise<Showroom>;
  delete: (id: string) => void;
  //getShowroom: (id: string) => Promise<Showroom>;
}

export const ShowroomsContext = React.createContext<ShowroomsContext>(null!);

const ShowroomsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAll, create, delete: __delete } = useApi().showrooms;
  const { getAll: getItems } = useApi().items;
  const [showrooms, setShowrooms] = useState<Array<ShowroomWithThumbnail>>([]);

  const createShowroom = (title: string) =>
    create(title)
      .then(resp => resp.json())
      .then(showroom => {
        setShowrooms([...showrooms, { ...showroom, thumbnail: 'missing' }]);
        return showroom;
      });

  useEffect(() => {
    getAll()
      .then(resp => resp.json())
      .then(showrooms => showrooms.map(showroom => ({ ...showroom, thumbnail: 'loading' } as ShowroomWithThumbnail)))
      .then(showrooms => {
        showrooms.forEach((showroom, idx) => {
          getItems(showroom.id)
            .then(resp => resp.json())
            .then(items => {
              if (items.length > 0) {
                showrooms[idx].thumbnail = items[0].image;
              } else {
                showrooms[idx].thumbnail = 'missing';
              }
              setShowrooms([...showrooms]);
            });
        });
        return showrooms;
      })
      .then(setShowrooms);
  }, [getAll]);

  const _delete = useCallback((id: string) => {
    __delete(id).then(() => setShowrooms(showrooms.filter(showroom => showroom.id !== id)));
  }, []);

  return (
    <ShowroomsContext.Provider
      value={{
        showrooms,
        createShowroom,
        delete: _delete,
      }}
    >
      {children}
    </ShowroomsContext.Provider>
  );
};

export default ShowroomsContextProvider;
