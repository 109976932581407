import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import ShowroomItemTextField from './ShowroomItemTextField';
import useApi, { Snapshot } from '../../../hooks/useApi';
import { ItemButtonsContainer, ItemIconContainer } from './Showroom';
import { Bag, Heart } from '../../../components/icons';
import Color from 'color';

const ItemButtonsContainerBottonm = styled(ItemButtonsContainer)`
  bottom: 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
`;

const ItemDetailsContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

const ShowroomItem = ({ id }: { id: string }) => {
  const {
    items: { get, update },
  } = useApi();

  const [item, setItem] = useState<Snapshot>(null!!);

  const updateItem = useCallback(
    e => {
      if (item) {
        // @ts-ignore
        update({ id: item.id, [e.target.name]: e.target.value });
      }
    },
    [item]
  );

  useEffect(() => {
    get(id)
      .then(resp => resp.json())
      .then(setItem);
  }, []);

  const theme = useContext(ThemeContext);

  const favorite = () => {
    const entity = { id: item.id, favorited: !item.favorited };
    // @ts-ignore
    update(entity);
    setItem({ ...item, favorited: !item.favorited });
  };

  const cart = () => {
    const entity = { id: item.id, interested: !item.interested };
    // @ts-ignore
    update(entity);
    setItem({ ...item, interested: !item.interested });
  };

  const heartBackgroundColor = Color('rgb(214, 74, 237)').string();
  const cartBackgroundColor = Color('rgb(61, 183, 62)').string();

  const defaultColor = Color(theme.colors.white)
    .fade(0.6)
    .string();

  return (
    <>
      {item && (
        <ItemDetailsContainer>
          <div style={{ position: 'relative' }}>
            <ItemButtonsContainerBottonm>
              <ItemIconContainer>
                <Heart
                  circle={true}
                  size={3}
                  onClick={favorite}
                  borderSize={'0'}
                  color={'white'}
                  background={item.favorited ? heartBackgroundColor : defaultColor}
                />
              </ItemIconContainer>
              <ItemIconContainer>
                <Bag
                  circle={true}
                  size={3}
                  onClick={cart}
                  borderSize={'0'}
                  color={'white'}
                  background={item.interested ? cartBackgroundColor : defaultColor}
                />
              </ItemIconContainer>
            </ItemButtonsContainerBottonm>

            <img src={item.image} style={{ borderRadius: '0.5rem', width: '100%' }} alt="" />
          </div>

          <ShowroomItemTextField
            label={'Name'}
            value={item.name || ''}
            name="name"
            onChange={e => setItem({ ...item, name: e.target.value })}
            onBlur={updateItem}
          />

          <ShowroomItemTextField
            label={'Price'}
            value={item.price || ''}
            name="price"
            onChange={e => setItem({ ...item, price: e.target.value })}
            onBlur={updateItem}
          />
          <ShowroomItemTextField
            label={'Size'}
            value={item.size || ''}
            name="size"
            onChange={e => setItem({ ...item, size: e.target.value })}
            onBlur={updateItem}
          />
          <ShowroomItemTextField
            label={'Type'}
            value={item.type || ''}
            name="type"
            onChange={e => setItem({ ...item, type: e.target.value })}
            onBlur={updateItem}
          />
          <ShowroomItemTextField
            label={'Color'}
            value={item.color || ''}
            name="color"
            onChange={e => setItem({ ...item, color: e.target.value })}
            onBlur={updateItem}
          />
          <ShowroomItemTextField
            label={'Brand'}
            value={item.brand || ''}
            name="brand"
            onChange={e => setItem({ ...item, brand: e.target.value })}
            onBlur={updateItem}
          />

          <ShowroomItemTextField
            label={'Notes'}
            value={item.notes || ''}
            name="notes"
            onChange={e => setItem({ ...item, notes: e.target.value })}
            onBlur={updateItem}
          />
        </ItemDetailsContainer>
      )}
    </>
  );
};

export default ShowroomItem;
