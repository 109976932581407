import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import TextField from './components/TextField';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import BusinessIcon from '@material-ui/icons/StorefrontOutlined';
import PasswordIcon from '@material-ui/icons/LockOutlined';
import LinkText from '../../components/LinkText';
import { signUp } from '../../api/ShowtimeAPI';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const [status, setStatus] = useState<'processing' | Error | null>(null);

  const onSubmit = () => {
    setStatus('processing');
    signUp(username, email, password)
      .then(resp => resp.json())
      .then(resp => history.push('/login'))
      .catch(err => (err.json && err.json()) || undefined)
      .then(err => {
        setStatus(new Error('Unknown error'));
        if (!err) {
          return;
        }

        console.log(err);
      });
  };

  return (
    <Flex direction="column">
      <Flex justifyContent="center" style={{ margin: '2em 2em 0 2em' }}>
        <Logo />
      </Flex>
      <Flex direction="column" alignItems="center" justifyContent="center" style={{ margin: '2em 2em 0 2em' }}>
        {status instanceof Error && <div>An error occurred</div>}
        <TextField
          icon={AccountIcon}
          placeholder={'Name'}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <TextField
          icon={MailIcon}
          placeholder={'Email (name@email.com)'}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Route path={'/register/retailer'}>
          <TextField icon={BusinessIcon} placeholder={'Business Name'} />
        </Route>
        <TextField
          icon={PasswordIcon}
          placeholder={'Password'}
          type={'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button style={{ marginTop: '1em' }} onClick={onSubmit}>
          {status === 'processing' ? 'Creating an Account...' : 'Create an Account'}
        </Button>
        <LinkText to="/login" style={{ marginTop: '1em' }}>
          Already have an account?
        </LinkText>
      </Flex>
    </Flex>
  );
};

export default Register;
