import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { ShowroomItemsList } from '../common/Showroom';
import styled from 'styled-components';
import { HeaderText } from '../../../components/HeaderBar';
import useMeetingContext from '../../../hooks/useMeetingContext';
import useShowroomContext from '../../../hooks/useShowroomContext';
import ShoppingCart from '../common/ShoppingCart';
import Flex from '../../../components/Flex';
import { Share, Camera, Cart, Trash } from '../../../components/icons';
import MainView from '../../../components/MainView';
import ShowroomItem from '../common/ShowroomItem';
import useShowroomsContext from '../../../hooks/useShowroomsContext';
import { ShowroomWithThumbnail } from '../../../state/ShowroomsContext';

import useApi from '../../../hooks/useApi';
import { refreshItemsListEventCreator } from '../../../utils/dataChannelEvents';
import { confirmAlert } from 'react-confirm-alert';

const OverflowContainer = styled.div<{ open: boolean }>`
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  top: 0;
  ${({ open }) => !open && 'pointer-events: none;'}
  z-index: 100;
`;

const Container = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  transform: translateY(${({ open }) => (open ? 0 : 100)}%);
  background: white;
  transition: 250ms;
  z-index: 100;
  overflow-y: scroll;
  box-sizing: border-box;
`;

const Header = styled.div`
  width: 100%;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 1em 1em 1em;
  box-sizing: border-box;
  border-bottom: 1px solid #c5c5c5;
`;

const BottomNavigationContainer = styled(Flex)`
  padding: 0.5em;
  background: rgb(96, 157, 245);
  background: linear-gradient(90deg, rgba(96, 157, 245, 1) 0%, rgba(115, 215, 195, 1) 100%);
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ShowroomHeaderControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ControlButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  font-family: sans-serif;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ShowroomHeaderControls = () => {
  const { setShowroomViewState } = useMeetingContext();
  const {
    selectItemsMode,
    setSelectItemsMode,
    selectAllItems,
    unselectAllItems,
    areAllItemsSelected,
  } = useShowroomContext();

  const doneSelectingItems = () => {
    unselectAllItems();
    setSelectItemsMode(false);
  };

  return (
    <ShowroomHeaderControlsContainer>
      {!selectItemsMode ? (
        <>
          <ControlButton
            onClick={() => {
              setShowroomViewState('closed');
            }}
          >
            Done
          </ControlButton>
          <ControlButton
            onClick={() => {
              setSelectItemsMode(true);
            }}
          >
            Select
          </ControlButton>
        </>
      ) : (
        <>
          {areAllItemsSelected() ? (
            <ControlButton onClick={unselectAllItems}>Deselect All</ControlButton>
          ) : (
            <ControlButton onClick={selectAllItems}>Select All</ControlButton>
          )}
          <ControlButton onClick={doneSelectingItems}>Done</ControlButton>
        </>
      )}
    </ShowroomHeaderControlsContainer>
  );
};

const HeaderControls = styled.div`
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 1em 1em 1em;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ShowroomView = () => {
  const { showroomViewState, setShowroomViewState } = useMeetingContext();
  const { roomId, refreshItems, selectedItemsSize, removeSelectedItems } = useShowroomContext();
  const { showrooms } = useShowroomsContext();

  const { items } = useApi();
  const { emmitDataChannelEvent } = useMeetingContext();
  const [showroom, setShowroom] = useState<ShowroomWithThumbnail | null>(null);

  const createItem = useCallback(
    (image: Blob) =>
      items.createWithImage(roomId, 'New Item', image).then(resp => {
        emmitDataChannelEvent(refreshItemsListEventCreator());
        refreshItems();
        return resp;
      }),
    [items, roomId]
  );

  const validateFile = (file: File, allowedExtensions: string[]) => {
    let split = file.name.split('.');
    let type = split[split.length - 1];
    if (!allowedExtensions.includes(type)) {
      alert('File type not allowed. Try one of the following: ' + allowedExtensions.join(', '));
      return false;
    }
    if (file.size / 1024 / 1024 > 30) {
      alert('File too large. File size cannot exceed 30 mb');
      return false;
    }

    return true;
  };

  const removeItems = () => {
    confirmAlert({
      message: 'Would you like to remove selected items?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeSelectedItems();
            emmitDataChannelEvent(refreshItemsListEventCreator());
          },
        },
        { label: 'Cancel', onClick: () => {} },
      ],
    });
  };

  const getData = (e: ChangeEvent, file: File) => {
    if (file && validateFile(file, ['png', 'jpg', 'jpeg'])) {
      return createItem(file);
    }
  };

  useEffect(() => {
    let showroom = showrooms.find(showroom => {
      return showroom.id == roomId;
    });
    if (showroom) setShowroom(showroom);
  }, [showrooms, roomId]);

  return (
    <OverflowContainer open={showroomViewState !== 'closed'}>
      <Container open={showroomViewState !== 'closed'}>
        {showroomViewState === 'list' && (
          <>
            <HeaderControls>
              {' '}
              <ShowroomHeaderControls />{' '}
            </HeaderControls>
            <HeaderContent>
              <HeaderText> {showroom?.title}</HeaderText>
            </HeaderContent>
            <MainView>
              <ShowroomItemsList onItemClick={item => setShowroomViewState(item.id)} />
            </MainView>
            <BottomNavigationContainer justifyContent="space-around" alignItems="center">
              <Share disabled={true} color={'white'} size={3} />
              <FileInput
                type="file"
                name="file"
                id="file"
                onChange={e => {
                  if (e?.target?.files?.length !== 0) {
                    //@ts-ignore
                    getData(e, e.target.files[0]);
                  }
                }}
              />
              <label htmlFor="file">
                <Camera color={'white'} size={3} />
              </label>
              <Cart color={'white'} size={3} onClick={() => setShowroomViewState('cart')} />

              {selectedItemsSize > 0 ? (
                <Trash color={'white'} onClick={removeItems} size={3} />
              ) : (
                <Trash disabled={true} color={'white'} size={3} />
              )}
            </BottomNavigationContainer>
          </>
        )}

        {showroomViewState === 'cart' && (
          <>
            <Header>
              <HeaderText>
                {' '}
                <span onClick={() => setShowroomViewState('list')}>&lt;</span> Shopping Cart
              </HeaderText>
            </Header>
            <ShoppingCart />
          </>
        )}

        {typeof showroomViewState === 'number' && (
          <>
            <Header>
              <HeaderText>
                {' '}
                <span onClick={() => setShowroomViewState('list')}>&lt;</span> Item Information
              </HeaderText>
            </Header>
            <MainView>
              <ShowroomItem id={showroomViewState.toString()} />
            </MainView>
          </>
        )}
      </Container>
    </OverflowContainer>
  );
};

export default ShowroomView;
