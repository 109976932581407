import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';
import useVideoContext from './useVideoContext';
import useMeetingContext from './useMeetingContext';

export default function useLocalAudioToggle() {
  const { localTracks, getLocalAudioTrack, microphoneDisabled, setMicrophoneDisabled } = useVideoContext();
  const audioTrack = !microphoneDisabled && (localTracks.find(track => track.kind === 'audio') as LocalAudioTrack);
  const {
    room: { localParticipant },
  } = useMeetingContext();

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      setMicrophoneDisabled(true);
      if (localParticipant) {
        try {
          const localTrackPublication = localParticipant.unpublishTrack(audioTrack);
          console.log('publication to remove', localTrackPublication);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit('trackUnpublished', localTrackPublication);
        } catch (e) {
          console.error(e);
        }
      }
      audioTrack.stop();
    } else {
      setMicrophoneDisabled(false);
      getLocalAudioTrack().then((track: LocalAudioTrack | null) => {
        if (!track) {
          // TODO: tell the user that there was no audio source detected
          return;
        }
        if (localParticipant) {
          localParticipant.publishTrack(track, { priority: 'low' });
        }
      });
    }
  }, [audioTrack, getLocalAudioTrack, localParticipant]);

  return [!!audioTrack, toggleAudioEnabled] as const;
}
