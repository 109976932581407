export enum DataChannelEvents {
  REFRESH_ITEMS_LIST = 'refresh_items_list',
  CHANGE_MAIN_PARTICIPANT = 'change_main_participant',
  END_MEETING = 'end_meeting',
}

enum BinaryDataChannelEvents {
  NO_MESSAGE = 0,
  REFRESH_ITEMS_LIST = 1,
  CHANGE_MAIN_PARTICIPANT = 2,
  END_MEETING = 3,
}

const numBytesForChangeParticipantMsg = 9;

export interface DataChannelEvent<T> {
  event: DataChannelEvents;
  sourceUserId?: string;
  payload: T;
}

export type ChangeMainParticipantEventPayload = number;
type ChangeMainParticipantEventType = DataChannelEvent<ChangeMainParticipantEventPayload>;
export const changeMainParticipantEventCreator = (participantId: number): ChangeMainParticipantEventType => ({
  event: DataChannelEvents.CHANGE_MAIN_PARTICIPANT,
  payload: participantId,
});

export const refreshItemsListEventCreator = (): DataChannelEvent<null> => ({
  event: DataChannelEvents.REFRESH_ITEMS_LIST,
  payload: null,
});

export const endMeetingEventCreator = (): DataChannelEvent<null> => ({
  event: DataChannelEvents.END_MEETING,
  payload: null,
});

export class BinaryMessage {
  convertTo(dataEvent: DataChannelEvent<any>): ArrayBuffer | null {
    switch (dataEvent.event) {
      case DataChannelEvents.REFRESH_ITEMS_LIST: {
        let array = new ArrayBuffer(1);
        let view = new DataView(array);
        view.setUint8(0, BinaryDataChannelEvents.REFRESH_ITEMS_LIST);
        return array;
      }

      case DataChannelEvents.CHANGE_MAIN_PARTICIPANT: {
        let array = new ArrayBuffer(numBytesForChangeParticipantMsg);
        let view = new DataView(array);
        let participant = dataEvent.payload;
        let sourceId = parseInt(dataEvent.sourceUserId || '0');

        view.setUint8(0, BinaryDataChannelEvents.CHANGE_MAIN_PARTICIPANT);
        view.setUint32(1, participant, true);
        view.setUint32(5, sourceId, true);
        return array;
      }

      case DataChannelEvents.END_MEETING: {
        let array = new ArrayBuffer(1);
        let view = new DataView(array);
        view.setUint8(0, BinaryDataChannelEvents.END_MEETING);
        return array;
      }
    }
  }

  convertFrom(dataArray: ArrayBuffer): DataChannelEvent<any> | null {
    // verify the message has the minimum bytes
    if (dataArray.byteLength < 1) return null;

    let view = new DataView(dataArray);

    // look at the message ID
    let msg = view.getUint8(0);
    switch (msg) {
      case BinaryDataChannelEvents.REFRESH_ITEMS_LIST:
        return refreshItemsListEventCreator();

      case BinaryDataChannelEvents.CHANGE_MAIN_PARTICIPANT:
        if (dataArray.byteLength < numBytesForChangeParticipantMsg) {
          console.log("CHANGE_MAIN_PARTICIPANT doesn't have the right amount of data!");
          return null;
        }
        let participant = view.getUint32(1, true);
        let sourceId = view.getUint32(5, true);
        let dataEvent = changeMainParticipantEventCreator(participant);
        dataEvent.sourceUserId = sourceId.toString();
        return dataEvent;

      case BinaryDataChannelEvents.END_MEETING:
        return endMeetingEventCreator();
    }
    return null;
  }
}
